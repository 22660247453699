import {
    ContentChild,
    Directive,
    TemplateRef,
  } from "@angular/core";
  
  @Directive({
    selector: "williams-ui-platform-window-titlebar",
  })
  export class WindowTitlebarDirective {
    @ContentChild("titlebarTemplate")
    titlebarTemplate!: TemplateRef<any>;
  }