<div class="button-group-layout">
  <kendo-buttongroup selection="single">
    <button *ngFor="let b of buttons"
            kendoButton [toggleable]="true" (click)="onButtonClick(b)"
            [fillMode]="b.fillMode??'none'" [themeColor]="b.themeColor??'none'"
            [disabled]="b.disabled??false" [(selected)]="!!b.selected"
    >
        <mat-icon  *ngIf="b.icon" [fontIcon]="b.icon"></mat-icon>
      {{ b.text }}
    </button>
  </kendo-buttongroup>
</div>