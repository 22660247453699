<kendo-stacklayout gap="16px" [orientation]="orientation" [align]="align" [classList]="classList">
  <div [style.width.%]="100"> 
        <div class="tabs">
          <kendo-buttongroup selection="single">
            <button *ngFor="let tab of tabs; index as i" kendoButton [toggleable]="true" (click)="handleLinkClick(tab)"
              [selected]="tab.selected" class="k-button-outline-primary">  
                <williams-ui-platform-badge-container>
                    {{ tab.title }}
                  <williams-ui-platform-badge classList="w-position-initial badge-orange w-mb-hair" *ngIf="tab.indicatorText" [size]="'small'" [align]="{horizontal:'end'}">{{tab.indicatorText}}</williams-ui-platform-badge>
                </williams-ui-platform-badge-container>
            </button>
          </kendo-buttongroup>
        </div>
  </div>
  <div [style.width.%]="100" [style.height.%]="100">
      <ng-content></ng-content>
  </div>
</kendo-stacklayout>