<kendo-treeview
    #kendoTreeViewInstance="kendoTreeView"
    kendoTreeViewExpandable
    kendoTreeViewHierarchyBinding
    customCheck
    [nodes]="nodes"
    [filterable]="filterable"
    [filter]="filter"
    [textField]="textField"
    [hasChildren]="hasChildren"
    [children]="children"
    [loadOnDemand]="false"
    [childrenField]="childrenField"
    [(expandedKeys)]="expandedKeys"
    [(checkedKeys)]="checkedKeys"
    [size]="size"
    (addItem)="addItemHandler($event)"
    (checkedChange)="checkedChangeHandler($event)"
    (nodeClick)="onClickHandler($event)"
    (checkedKeysChange)="onCheckedKeysChange($event)"
    [class]="classList"
>
    <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index" *ngIf="treeViewTemplateDirective?.treeNodeTemplate">
        <ng-container   *ngTemplateOutlet="treeViewTemplateDirective?.treeNodeTemplate;context: { $implicit: dataItem, dataItem, index }">
        </ng-container>
    </ng-template>
</kendo-treeview>