import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Orientation } from '@progress/kendo-angular-inputs';

export interface Content {
  contentText: string;
  contentTitle: string;
  selectedData?: any,
  columnDisplayForPrint?:any
}
@Component({
  selector: 'williams-ui-platform-content-display',
  templateUrl: './content-display.component.html',
  styleUrls: ['./content-display.component.scss']
})
export class ContentDisplayComponent implements OnInit {
  
  @Input() content! :Content ;
  @Input() showPrintButton = false;
  
  @ViewChild('contentTitleDiv')
  contentTitleDiv!: ElementRef;

  @ViewChild('contentBodyDiv')
  contentBodyDiv!: ElementRef;
  
  public orientation: Orientation = "vertical";
  constructor(){}
  ngOnInit(): void {
    
  }

  printClickHandler(){
    const title: HTMLDivElement = this.contentTitleDiv.nativeElement;
    const messageBody: HTMLDivElement = this.contentBodyDiv.nativeElement;
    let printWindow = window.open(' ', 'div', 'height=800,width=1000');
    printWindow?.document.write('</head><body >');
    let columnData:string = "";
    if(this.content.hasOwnProperty('columnDisplayForPrint')){
      this.content.columnDisplayForPrint.forEach((column:any) => {
        columnData = columnData  + `<div style="line-height:1.5;"><b>${column.title} : </b> ${this.content.selectedData[column.field]}</div>`;
      })
    }
    printWindow?.document.write(title.innerHTML);
    printWindow?.document.write(`<div style='column-count:2'>${columnData}</div>`);
    printWindow?.document.write(`<div style='border:1px solid rgba(16, 24, 32, 0.3);margin-top:20px;padding-left:10px;padding-right:10px;height:85%'>${messageBody.innerHTML}</div>`);
    printWindow?.document.write('</body></html>');
    printWindow?.print();
    printWindow?.close();
  }
}
