import { Component, forwardRef, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFillMode, InputRounded, InputSize } from '@progress/kendo-angular-inputs';
import { BaseAccessor } from '../base/base-accessor';
@Component({
  selector: 'williams-ui-platform-masked-text-input',
  templateUrl: './masked-text-input.component.html',
  styleUrls: ['./masked-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaskedTextInputComponent),
      multi: true
    }
  ]
})
export class MaskedTextInputComponent extends BaseAccessor {
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() mask = '';
  @Input() value:string = '';
  @Input() showErrors = false;
  @Input() fillMode: InputFillMode = 'solid';
  @Input() rounded: InputRounded = 'none';
  @Input() size: InputSize = 'medium';
  @Input() prompt = '';
  @Input() promptPlaceholder = '';
  @Input() includeLiterals= false;
  @Input() maskOnFocus = false;

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer)
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.control.markAsPristine()
    },0)
  }
}
