import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-value-axis-item',
  templateUrl: './value-axis-item.component.html',
  styleUrls: ['./value-axis-item.component.scss']
})
export class ValueAxisItemComponent implements OnInit {
  @Input() title: any ;
  @Input() min!: number;
  @Input() max!: number;
  @Input() labels!:any;
  constructor() { }

  ngOnInit(): void {
  }

}
