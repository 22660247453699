<div [ngClass]="{'show-errors': showErrors}">
    <kendo-dropdowntree
            #dropdownTree
            kendoDropDownTreeExpandable
            [kendoDropDownTreeFlatBinding]="data"
            [clearButton]="clearButton"
            [dataItem]="dataItem"
            [disabled]="disabled"
            [fillMode]="fillMode"
            [filterSettings]="filterSettings"
            [formControl]="control"
            [itemDisabled]="itemDisabled"
            [listHeight]="listHeight"
            [loading]="loading"
            [loadOnDemand]="loadOnDemand"
            [parentIdField]="parentIdField"
            [placeholder]="placeholder"
            [popupSettings]="popupSettings"
            [readonly]="readonly"
            [rounded]="rounded"
            [size]="size"
            [textField]="textField"
            [title]="title"
            [valueField]="valueField"
            [value]="value"
            [valuePrimitive]="valuePrimitive"
            [filterable]="filterable"
            (close)="onClose($event)"
            (closed)="onClosed($event)"
            (filterChange)="onFilterChange($event)"
            (nodeCollapse)="onNodeCollapse($event)"
            (nodeExpand)="onNodeExpand($event)"
            (blur)="onBlur()"
            (focus)="onFocus($event)"
            (open)="onOpen($event)"
            (opened)="onOpened($event)"
            (valueChange)="onValueChange($event)"
    ></kendo-dropdowntree>
</div>