  <kendo-fileselect
    #kendoFileSelectInstance="kendoFileSelect"
    [restrictions]="fileRestrictions"
    [multiple]="false"
    [formControl]="control"
    [disabled]="disabled"
    (valueChange)="valueChange($event)"
    (remove) = "removeEventHandler($event)"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (select)="onSelect($event)"
  >
  <kendo-fileselect-messages
        [dropFilesHere]="dropCustomText"
        [select] = "select"
        [invalidFileExtension]="fileTypeCustomText"
        >
    </kendo-fileselect-messages>
  </kendo-fileselect>