import {
         CheckedState as KendoCheckedState, 
         TreeViewSize as KendoTreeViewSize, 
         TreeItemAddRemoveArgs as KendoTreeItemAddRemoveArgs,
         TreeItemLookup as  KendoTreeItemLookup,
         TreeItem as KendoTreeItem,
         FilterState as KendoFilterState,
         NodeClickEvent as KendoNodeClickEvent,
         TreeItemDragEvent as KendoTreeItemDragEvent,
         TreeItemDragStartEvent as KendoTreeItemDragStartEvent,
         TreeItemDropEvent as KendoTreeItemDropEvent
        } from "@progress/kendo-angular-treeview";

export declare type CheckedState = KendoCheckedState;

export declare type TreeViewSize = KendoTreeViewSize;
export interface TreeItemAddRemoveArgs extends KendoTreeItemAddRemoveArgs {}
export interface TreeItemLookup extends KendoTreeItemLookup {}
export interface TreeItem extends KendoTreeItem {}
export interface FilterState extends KendoFilterState {}
export interface NodeClickEvent extends KendoNodeClickEvent {}
export interface TreeItemDragEvent extends KendoTreeItemDragEvent {}
export interface TreeItemDragStartEvent extends KendoTreeItemDragStartEvent{}
export interface TreeItemDropEvent extends KendoTreeItemDropEvent{}
export interface TreeItemChildren { children: TreeItem[]; item: TreeItem; }


