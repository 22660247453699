import { TemplateRef } from "@angular/core";

export interface GridColumnDef {
    field: string;
    title: string;
    cellTemplate?: TemplateRef<any> | null;
    headerTemplate?: TemplateRef<any> | null;
    groupHeaderTemplate? : any;
    editable: boolean;
    // Flags to enable/disable editing columns based on type of action
    editConfig?: {
        add: boolean;
        edit: boolean;
    };
    editType?: 'text' | 'dropdown' | 'numeric';
    editTemplate?: TemplateRef<any>;
    dropdownOptions?: { [key: string]: any }[];
    dropdownSettings?: {
        valuePrimitive: boolean;
        valueField: string;
        textField: string;
    },
    numericEditorSettings? : {
        decimal: 0
    }
    aggregate?: {
        show: true;
        label: string;
        total?: number;
    };
    width?: number;
    sticky?:boolean;
    isHyperlink?:boolean;
    formControlName?:string;
    sortable?: boolean;
    groupable?: boolean;
    filterable?: boolean;
    cssClass?: string;
    columnMenu?:boolean;
    headerClass?: string;
    footerClass?: string;
    numberFormat?: {
        required: boolean,
        locale?: string
      };  
    hidden?:boolean;
}