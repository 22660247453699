export const ROW_HEIGHT = 28;
export const DEFAULT_HEADER_HEIGHT = 30;
export const DEFAULT_FOOTER_ROW_HEIGHT = 30;
export const DEFAULT_COLUMN_FOOTER_HEIGHT = 30;

export interface CalculateGridHeightInputOptions {
  headerHeight: number;
  columnFooterHeight: number;
}

const defaultInputOptions: CalculateGridHeightInputOptions = {
  headerHeight: DEFAULT_HEADER_HEIGHT,
  columnFooterHeight: DEFAULT_COLUMN_FOOTER_HEIGHT
}

// Calculates the grid height based on no of rows and options. This does not consider the height of footer row where row count label is shown
export const calculateGridHeight = (rowLength: number, options: Partial<CalculateGridHeightInputOptions> = defaultInputOptions ): number => {
  const { headerHeight, columnFooterHeight } = { ...defaultInputOptions , ...options }
  return rowLength * ROW_HEIGHT + headerHeight + columnFooterHeight + 8; // 8px buffer to handle scrollbar overlapping issue in windows
}