import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { formatDate, Location } from "@angular/common";
import { Subject, takeUntil } from "rxjs";
import { PDF_VIEWER } from "./constant";

@Component({
  selector: "williams-ui-platform-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  showHomePage = true;
  showPdf = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  date = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');

  constructor(
      private router: Router,
      private location: Location,
  ) {
    this.showHomePage = this.location.path().split('?')[0] === `/${PDF_VIEWER}` ? false : true;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}