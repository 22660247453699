<kendo-window
  [width]="width"
  [height]="height"
  [minWidth]="minWidth"
  [minHeight]="minHeight"
  [left]="left"
  [top]="top"
  [title]="title"
  [resizable]="resizable"
  [draggable]="draggable"
  [keepContent]="keepContent"
  [state]="state"
  (opened)="onOpened($event)"
  (closed)="onClosed($event)"
  (dragEnd)="onDragEnd($event)"
  (dragStart)="onDragStart($event)"
  (heightChange)="onHeightChange($event)"
  (leftChange)="onLeftChange($event)"
  (resizeEnd)="onResizeEnd($event)"
  (resizeStart)="onResizeStart($event)"
  (stateChange)="onStateChange($event)"
  (topChange)="onTopChange($event)"
  (widthChange)="onWidthChange($event)"
>
  <kendo-window-titlebar *ngIf="windowTitlebarDirective">
    <ng-container
      *ngTemplateOutlet="windowTitlebarDirective.titlebarTemplate"
    ></ng-container>
  </kendo-window-titlebar>

  <ng-content></ng-content>
</kendo-window>
