<williams-ui-platform-stack-layout orientation="horizontal">
    <div style="width: 260px;">
        <div class="williamsLogo">
            <img src="assets/w-ui-angular/images/wms-2color.png" alt="" class="williamsImg">
        </div>
        <ul class="ulParent">
            <ng-container *ngFor="let item of menus">
                <li class="drawerItem" *ngIf="!item?.parentId">
                    <button class="drawerItems d-flex justify-between" (click)="toggleChildren(item)">
                        <span class="drawerText">{{ item.text }}</span>
                        <div *ngIf="hasChildren(item.id)" class="drawerArrow">
                            <img src="assets/w-ui-angular/images/expand.svg" alt="">
                        </div>
                    </button>
                    <div *ngIf="activeParentId === item.id" class="ulContainerTransparent">
                        <div class="d-flex gap-0 flex-row height-100-percent">
                            <div class="ulContainer">
                                <ul class="childMenu no-blur ulChild">
                                    <li *ngFor="let child of getChildren(item.id)">
                                        <button class="drawerItems d-flex justify-between" (click)="onSelect(child)">
                                            <span class="drawerText">{{ child.text }}</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <button class="closeButton" (click)="closeChildNavBar()">
                                <img style="margin-top: 3px;" src="assets/w-ui-angular/images/previous.svg" alt="">
                            </button>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
        <div class="drawerFooterContainer">
            <div class="d-flex align-center flex-row">
                <img src="assets/w-ui-angular/images/phone_red.svg" alt="" style="margin-right: 8px;">
                <div>
                    <h6 class="w-small-text-normal w-m-0 w-color-error-pt9">24-Hour Pipeline Emergency</h6>
                    <p class="w-h1-bold w-m-0 w-color-error-pt9">1-800-972-7733</p>
                </div>
            </div>
        </div>
        <div *ngIf="activeParentId !== null" class="backdrop"></div>
    </div>
    <div class="toggleblur"></div>
</williams-ui-platform-stack-layout>