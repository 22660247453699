<williams-ui-platform-stack-layout themeColor="base">
    <williams-ui-platform-button matIcon="delete" fillMode="clear" themeColor="primary" (clicked)="onDeleteClick()">

    </williams-ui-platform-button>
    <kendo-dropdownbutton
        icon="more-vertical"
        [data]="commandsData"
        [popupSettings]="{ align: 'right' }"
        fillMode="clear"
        [disabled]="disabled"
        (itemClick)="onItemClick($event)"
    >
    </kendo-dropdownbutton>
</williams-ui-platform-stack-layout>