<ng-container *ngIf="!hasCheckbox; else treeViewCheckbox">
    
    <kendo-treeview
    #kendoTreeViewInstance="kendoTreeView"
    kendoTreeViewExpandable
    [kendoTreeViewSelectable]="{ mode: mode }"
    kendoTreeViewHierarchyBinding 
    [nodes]="nodes"
    [filterable]="filterable"
    [filter]="filter"
    [textField]="textField"
    [hasChildren]="hasChildren"
    [children]="children"
    [childrenField]="childrenField"
    [(expandedKeys)]="expandedKeys"
    [(selectedKeys)]="selectedKeys"
    [size]="size"
    (addItem)="addItemHandler($event)"
    (checkedChange)="checkedChangeHandler($event)"
    (nodeClick)="onClickHandler($event)"
    (selectionChange)="selectionChangeHandler($event)"
    [class]="classList"
    >
    <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index" *ngIf="treeViewTemplateDirective?.treeNodeTemplate">
        <ng-container   *ngTemplateOutlet="treeViewTemplateDirective?.treeNodeTemplate;context: { $implicit: dataItem, dataItem, index }">
        </ng-container>
    </ng-template>
    </kendo-treeview>
</ng-container>

<ng-template #treeViewCheckbox>
    <kendo-treeview
    #kendoTreeViewInstance="kendoTreeView"
    textField="text"
    [nodes]="nodes"
    [children]="children"
    [hasChildren]="hasChildren"
    >
    <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index" *ngIf="treeViewTemplateDirective?.treeNodeTemplate">
        <ng-container   *ngTemplateOutlet="treeViewTemplateDirective?.treeNodeTemplate;context: { $implicit: dataItem, dataItem, index }">
        </ng-container>
    </ng-template>
    </kendo-treeview>
</ng-template>


