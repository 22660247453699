import { getFieldValueFromDataItemRecursively } from "./helpers";

export const findOverlapping = (records: any[], editedRecords: any[], compareKeys: { key: string; type?: "string" | "number" | "date", editedKey?:string, format?: string, rangeStart: string, rangeEnd : string}[],noInfinityEndDate=true ,  isGridUpdated = true): number[] => {
    for (let editedRecord of editedRecords) {
      const result = [];
      for (let [index, record] of records.entries()) {
        const isDuplicate = compareKeys.every((item) => {
          const keyType = item.type ?? "string";
          const editedRecordValue = getFieldValueFromDataItemRecursively(
            editedRecord,
            item.editedKey ? item.editedKey : item.key
          );
          const recordValue = getFieldValueFromDataItemRecursively(
            record,
            item.key
          );
          const isNewItem = record.isNewItem ? true : false;

          const editedRecordStartDate = getFieldValueFromDataItemRecursively(
            editedRecord,
            item.rangeStart
          );
          const editedRecordEndDate = getFieldValueFromDataItemRecursively(
            editedRecord,
            item.rangeEnd
          );

          const recordStartValue = getFieldValueFromDataItemRecursively(
            record,
            item.rangeStart
          );
          const recordEndValue = getFieldValueFromDataItemRecursively(
            record,
            item.rangeEnd
          );

          if (keyType == "date" && editedRecordValue === recordValue) {
            return compareDatesOverlapping(
              editedRecordStartDate,
              noInfinityEndDate ? editedRecordEndDate : new Date(8640000000000000),
              recordStartValue,
              noInfinityEndDate ? recordEndValue : new Date(8640000000000000),
              isNewItem
            );
          }

          return editedRecordValue == recordValue;
        });

        if (isDuplicate) {
          result.push(index);
          if (result.length == 1 && !isGridUpdated) {
            return result;
          }
          if (result.length == 2 && isGridUpdated) {
            return result;
          }
        }
      }
    }
    return [];
  }

  function compareDatesOverlapping(date1: Date, date2: Date,date3: Date, date4: Date, isNewItem : boolean) {
    const editedStartMonth = date1.getMonth() + 1;
    const editedStartYear = date1.getFullYear();
    const editedStartRange = new Date(
      `${editedStartMonth}/1/${editedStartYear}`
    );

    const editedEndMonth = date2.getMonth() + 1;
    const editedEndYear = date2.getFullYear();
    const editedEndRange = new Date(`${editedEndMonth}/1/${editedEndYear}`);

    const recordStartMonth = date3.getMonth() + 1;
    const recordStartYear = date3.getFullYear();
    const recordStartRange = new Date(
      `${recordStartMonth}/1/${recordStartYear}`
    );

    const recordEndMonth = date4.getMonth() + 1;
    const recordEndYear = date4.getFullYear();
    const recordEndRange = new Date(`${recordEndMonth}/1/${recordEndYear}`);

    return (
      (editedStartRange >= recordStartRange &&
        editedStartRange <= recordEndRange) ||
      (editedEndRange >= recordStartRange &&
        editedEndRange <= recordEndRange) ||
      (editedStartRange <= recordStartRange &&
        editedEndRange >= recordStartRange) ||
      (recordStartRange > editedStartRange && editedEndRange > recordStartRange)
    );
        
  }