import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'williams-ui-platform-digital-clock',
  templateUrl: './digital-clock.component.html',
  styleUrls: ['./digital-clock.component.scss']
})
export class DigitalClockComponent {
  @Input() cls="";
  @Input() format='HH:mm';
  @Input() time: Date | null = null;
}
