<kendo-dialog-titlebar *ngIf="dialogTemplates.titleTemplate">
  <ng-container
    *ngTemplateOutlet="dialogTemplates.titleTemplate"
  ></ng-container>
</kendo-dialog-titlebar>
<div class="dialog-content w-p-4" *ngIf="dialogTemplates.contentTemplate">
  <ng-container
    *ngTemplateOutlet="dialogTemplates.contentTemplate"
  ></ng-container>
</div>
<kendo-dialog-actions *ngIf="dialogTemplates.actionTemplate">
  <ng-container
    *ngTemplateOutlet="dialogTemplates.actionTemplate"
  ></ng-container>
</kendo-dialog-actions>
