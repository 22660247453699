import { Injectable } from "@angular/core";
import { NotificationService, NotificationSettings } from "@progress/kendo-angular-notification";
import { Observable } from "rxjs";

export interface ToastNotificationSettings extends NotificationSettings { }

const DEFAULT_SETTINGS: Partial<ToastNotificationSettings> = {
    position: {
        horizontal: 'center',
        vertical: 'top'
    },
    animation: {
        type: 'slide',
        duration: 200
    },
    closable: true,
    hideAfter: 3000,
    type: {
        style: 'success',
        icon: true
    }
};

@Injectable({
    providedIn: 'root'
})
export class ToastNotificationService {
    constructor(private _notificationService: NotificationService) { }

    show(settings: ToastNotificationSettings): { hide: Function, afterHide?: Observable<any> } {
        const config: NotificationSettings = {
            ...DEFAULT_SETTINGS,
            ...settings
        }

        const ref = this._notificationService.show(config);

        // Manually hiding notification after hideAfter delay because hideAfter property is ignored by Kendo when closable property is set to true.
        if (config.closable && config.hideAfter) {
            setTimeout(() => {
                ref.hide();
            }, config.hideAfter)
        }

        return {
            hide: () => ref.hide(),
            afterHide: ref.afterHide
        }
    }
}