<div class="wrap">
    <div class="summary-header" (click)="showHide()">
        <p [style.width]="headerTitleWidth">{{summaryHeader}}</p>
        <span [ngClass]="{'k-icon': true, 'k-i-chevron-up': show, 'k-i-chevron-down': !show}"></span>
    </div>
    <div class="grid-view-expandable-content borderless-kendo-grid" *ngIf="show">
        <ng-content select="[before-grid-view]"></ng-content>
        <div #content>
            <ng-content select="[grid-view]"></ng-content>
        </div>

        <williams-ui-platform-grid-view-layout [data]="data" [columns]="gridColumnDefs" *ngIf="content.children.length == 0">
        </williams-ui-platform-grid-view-layout>

        <ng-content select="[after-grid-view]"></ng-content>
    </div>
</div>
