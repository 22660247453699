<williams-ui-platform-stack-layout [align]="{ horizontal: 'start' }">
  <williams-ui-platform-button
    themeColor="primary"
    fillMode="outline"
    matIcon="first_page"
    [size]="size"
    [disabled]="disableAll"
    (clicked)="firstPage()"
  >
    First
  </williams-ui-platform-button>
  <williams-ui-platform-button
    themeColor="primary"
    fillMode="outline"
    matIcon="navigate_before"
    [size]="size"
    [disabled]="disableAll"
    (clicked)="previousPage()"
  >
    Prior
  </williams-ui-platform-button>
  <williams-ui-platform-button
    themeColor="primary"
    fillMode="outline"
    matIcon="navigate_next"
    [size]="size"
    [disabled]="disableAll"
    (clicked)="nextPage()"
  >
    Next
  </williams-ui-platform-button>
  <williams-ui-platform-button
    themeColor="primary"
    fillMode="outline"
    matIcon="last_page"
    [size]="size"
    [disabled]="disableAll"
    (clicked)="lastPage()"
  >
    Last
  </williams-ui-platform-button>
</williams-ui-platform-stack-layout>
