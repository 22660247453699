<kendo-dialog-titlebar>
  {{ settings.title }}
</kendo-dialog-titlebar>
<div class="dialog-content">
  <p class="w-mb-0" [innerHTML]="settings.content">
  </p>
</div>
<kendo-dialog-actions>
  <williams-ui-platform-button
    *ngIf="settings.cancelAction"
    [classList]="settings.cancelAction.cssClass ?? 'btn-outlined'"
    [matIcon]="settings.cancelAction.icon ?? 'close'"
    [imageUrl]="settings.cancelAction.imageUrl ?? ''"
    (clicked)="onActionButtonClick(settings.cancelAction)"
    themeColor="primary"
    fillMode="outline"
    width="100%"
    size="large"
  >
    {{ settings.cancelAction.text }}
  </williams-ui-platform-button>
  <williams-ui-platform-button
    [classList]="settings.confirmAction.cssClass ?? 'btn-primary'"
    [matIcon]="settings.confirmAction.icon ?? 'check'"
    [imageUrl]="settings.confirmAction.imageUrl ?? ''"
    (clicked)="onActionButtonClick(settings.confirmAction)"
    themeColor="primary"
    fillMode="solid"
    width="100%"
    size="large"
  >
    {{ settings.confirmAction.text }}
  </williams-ui-platform-button>
</kendo-dialog-actions>
