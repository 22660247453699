<ng-container *ngIf="!elementId; else focusedElement">
<kendo-textbox
  [ngClass]="{ 'transform-uppercase': upperCaseInput }"
  type="text"
  [formControl]="control"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [clearButton]="clearButton"
  [showErrorIcon]="showErrorIcon"
  [maxlength]="maxlength"
  [rounded]="rounded"
  [disabled]="disabled"
  [size]="size"
  [fillMode]="fillMode"
  [value]="value"
  (valueChange)="onValueChange($event)"
  [ngStyle]="inlineStyle"
  (blur)="onBlur()"
  (inputFocus)="onInputFocus()"
>
<ng-template kendoTextBoxPrefixTemplate *ngIf="textInputTemplates?.textInputPrefixTemplate">
  <ng-container *ngTemplateOutlet="textInputTemplates.textInputPrefixTemplate"></ng-container>
</ng-template>

<ng-template kendoTextBoxSuffixTemplate *ngIf="textInputTemplates?.textInputSuffixTemplate">
    <ng-container *ngTemplateOutlet="textInputTemplates?.textInputSuffixTemplate"></ng-container>
</ng-template>
</kendo-textbox>
</ng-container>
<ng-template #focusedElement>
  <kendo-textbox
  class="mt-4"
  type="text"
  [formControl]="control"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [clearButton]="true"
  [showErrorIcon]="showErrorIcon"
  [maxlength]="maxlength"
  [rounded]="rounded"
  [disabled]="disabled"
  [size]="size"
  [fillMode]="fillMode"
  (valueChange)="onValueChange($event)"
  [ngStyle]="inlineStyle"
  (blur)="onBlur()"
>
<ng-template kendoTextBoxPrefixTemplate *ngIf="textInputTemplates?.textInputPrefixTemplate">
  <ng-container *ngTemplateOutlet="textInputTemplates.textInputPrefixTemplate"></ng-container>
</ng-template>

<ng-template kendoTextBoxSuffixTemplate *ngIf="textInputTemplates?.textInputSuffixTemplate">
    <ng-container *ngTemplateOutlet="textInputTemplates?.textInputSuffixTemplate"></ng-container>
</ng-template>
</kendo-textbox>
</ng-template>
<kendo-stacklayout class="w-mt-1" [align]="!showErrorIcon ? {horizontal:'end'}:{horizontal:'stretch'}" *ngIf="(showErrorIcon && errorLabel) || showCharacterCount">
  <div *ngIf="showErrorIcon && errorLabel" class="w-color-error-1 w-font-italic">
    {{errorLabel}} is required
  </div>
  <div *ngIf="showCharacterCount" class="d-flex flex-justify-end w-font-italic w-color-compTxt-1">{{ counter }}</div>
</kendo-stacklayout>