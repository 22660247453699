<div
    kendoTooltip
    [tooltipTemplate]="tooltipTemplate"
    [titleTemplate]="titleTemplate"
    [filter]="filter"
    [showOn]="showOn"
    [position]="position"
    [closable]="closable"
    [closeTitle]="closeTitle"
    [callout]="callout"
    [offset]="offset"
    [showAfter]="showAfter"
    [tooltipClass]="tooltipClass"
    [tooltipHeight]="tooltipHeight"
    [tooltipWidth]="tooltipWidth"
  >
  <ng-content></ng-content>
</div>