<ng-container *ngIf="!gridReadonlyView">
  <kendo-switch
    *ngIf="control; else nonFormUsage"
    [checked]="checked"
    [disabled]="disabled"
    [offLabel]="offLabel"
    [onLabel]="onLabel"
    [readonly]="readonly"
    [size]="size"
    [thumbRounded]="thumbRounded"
    [trackRounded]="trackRounded"
    [formControl]="control"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (valueChange)="onValueChange($event)"
  ></kendo-switch>

  <ng-template #nonFormUsage>
    <kendo-switch
      [checked]="checked"
      [disabled]="disabled"
      [offLabel]="offLabel"
      [onLabel]="onLabel"
      [readonly]="readonly"
      [size]="size"
      [thumbRounded]="thumbRounded"
      [trackRounded]="trackRounded"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      (valueChange)="onValueChange($event)"
    ></kendo-switch>
  </ng-template>
</ng-container>

<div [ngClass]="['switch-grid-readonly', 'k-switch', sizeClass, checked ? 'is-checked' : '', 'd-flex', 'flex-align-center', 'flex-justify-center']" *ngIf="gridReadonlyView">
  {{ checked ? onLabel : offLabel }}
</div>
