import { ContentChild, Directive, TemplateRef } from '@angular/core';
@Directive({
  selector: 'williams-ui-platform-stepper-label-template'
})
export class StepperLabelTemplateDirective {
  @ContentChild('labelTemplate') labelTemplate!: TemplateRef<any>;
  constructor() { 
       
  }

}