import { Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { DateInputFillMode, DateInputRounded, DateInputSize, FormatSettings } from '@progress/kendo-angular-dateinputs';
import { BaseAccessor } from '../base/base-accessor';
import { InvalidDateFormat } from '../../../model/datepicker-constants';

const minYear = InvalidDateFormat.MIN_YEAR;
type CalendarView = "month" | "year" | "decade" | "century";
@Component({
  selector: 'williams-ui-platform-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent extends BaseAccessor implements OnInit{
  @Input() value:Date=new Date();
  @Input() format: string | FormatSettings="MM/dd/yyyy";
  //With some comments
  @Input() min!: Date;
  @Input() placeholder= "MM/DD/YYYY";
  @Input() showErrorMsg=false;
  @Input() max! : Date;
  @Input() fillMode: DateInputFillMode = 'solid';
  @Input() rounded: DateInputRounded = 'full';
  @Input() size: DateInputSize = 'medium';
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() classList = '';
  @Input() readOnlyInput = false;
  @Input() activeView :CalendarView = "month";
  @Input() bottomView :CalendarView = "month";
  @Input() disabledDates!: Date[] | ((date: Date) => boolean);
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() blur=new EventEmitter<any>();
  dateValue!: string;
  initialValidators!: Validators[];
  @Input() isValidateFormat = true;
  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }
  valueChangeHandler(event:Date){
    this.valueChange.emit(event);
  }

  onBlur(){
    this.blur.emit();
  }

  override ngOnInit() {
    if(this.formControlName){
      this.control = (<FormGroup>this.controlContainer?.control)?.get(this.formControlName);
      if(this.isValidateFormat) {
        this.initialValidators = this.control.validator;
        if(this.initialValidators) {
          this.control.setValidators([
            this.initialValidators, // Keep existing validators
            this.checkDateFormatValidation() // Add the custom validator
          ]);
        } else {
          this.control.setValidators([
            this.checkDateFormatValidation() // Add the custom validator
          ]);
        }
        this.control?.updateValueAndValidity();
      }
   }
  }

  onDateInputChange(event: any) {
    if(this.isValidateFormat) {
      this.dateValue = event?.target?.value;
      let control = (<FormGroup>this.controlContainer?.control)?.get(this.formControlName);
      control?.updateValueAndValidity();
    }
  }
  
  checkDateFormatValidation(): ValidatorFn | any {
    return (control: FormControl): { [key: string]: any } | null => {
      if (!this.dateValue || this.dateValue === InvalidDateFormat.MONTH_DAY_YEAR || this.dateValue === InvalidDateFormat.MONTH_YEAR || control.value) {
        if(control.value) {
          let year = new Date(control.value).getFullYear();
          if(year < minYear) {
            return { invalidDateFormat: true }; // Invalid partial date
          }
        }
        return null; // If date value is empty, consider it valid
      }
      if(new Date(this.dateValue).toString() === InvalidDateFormat.INVALID_DATE || 
        this.dateValue.indexOf(InvalidDateFormat.MONTH) !== -1 || 
        this.dateValue.indexOf(InvalidDateFormat.DAY) !== -1 || 
        this.dateValue.indexOf(InvalidDateFormat.YEAR) !== -1) {
        return { invalidDateFormat: true }; // Invalid partial date
      }
      return null;
    };
  }

}
