import { Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { ValueAxisItemComponent } from '../value-axis-item/value-axis-item.component';

@Component({
  selector: 'williams-ui-platform-value-axis',
  templateUrl: './value-axis.component.html',
  styleUrls: ['./value-axis.component.scss']
})
export class ValueAxisComponent implements OnInit {
  
  @ContentChildren(ValueAxisItemComponent)
  axisItems!: QueryList<ValueAxisItemComponent>;
  
  constructor() { }

  ngOnInit(): void {
  }

}
