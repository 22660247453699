<div class ="d-flex" [ngClass]="{ 'show-errors': showErrorMsg }">
  <kendo-datepicker
  [class]="classList"
  [formControl]="control"
  [(value)]="value"
  [format]="format"
  [min]="min"
  [placeholder]="placeholder"
  [max]="max" 
  calendarType="classic"
  [fillMode]="fillMode"
  [rounded]="rounded"
  [size]="size"
  [disabled]="disabled"
  [readonly]="readonly"
  [readOnlyInput]="readOnlyInput"
  [activeView]="activeView"
  [bottomView]="bottomView"
  [disabledDates]="disabledDates"
  (valueChange)="valueChangeHandler($event)"
  (blur)="onBlur()"
  (input)="onDateInputChange($event)"
> 
</kendo-datepicker>
<kendo-icon class="datePickerRequiredIcon w-icon-color w-mt-2 w-ml-n12" *ngIf="showErrorMsg"  name="warning"></kendo-icon>
</div>