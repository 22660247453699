<ng-container *ngIf="!elementId; else focussedbutton">
    <div *ngIf="control; else elseBlock">
        <input
                kendoCheckBox
                [formControl]="control"
                type="checkbox"
                [size]="size"
                [rounded]="rounded"
                [checked]="checked"
                (click)="onClick()"
                [disabled]="isDisabled"
                [indeterminate]="indeterminate"
        />
    </div>
    <ng-template #elseBlock>
        <input
                kendoCheckBox
                type="checkbox"
                [size]="size"
                [rounded]="rounded"
                [checked]="checked"
                [disabled]="isDisabled"
                [indeterminate]="indeterminate"
                (click)="onClick()"
        />
    </ng-template>
</ng-container>

<ng-template #focussedbutton>
    <div *ngIf="control; else elseBlock">
        <input
                kendoCheckBox
                [formControl]="control"
                type="checkbox"
                [size]="size"
                [rounded]="rounded"
                [checked]="checked"
                (click)="onClick()"
                [id]="elementId"
                [disabled]="isDisabled"
                [indeterminate]="indeterminate"
        />
    </div>
    <ng-template #elseBlock>
        <input
                kendoCheckBox
                type="checkbox"
                [size]="size"
                [rounded]="rounded"
                [checked]="checked"
                (click)="onClick()"
                [id]="elementId"
                [disabled]="isDisabled"
                [indeterminate]="indeterminate"
        />
    </ng-template>
</ng-template>