<kendo-card [style.width.%]="100" class="remove-border-trl">
    <kendo-card-body class="d-flex px-12 py-20">
      <div class="flex-self-start flex-1">
        <h3 class="title pt-5">{{ headerTitle }}</h3>
      </div>
      <div class="flex-content-end">
        <kendo-splitbutton
          *ngIf="menuItems.length > 0; else buttonRef"
          imageUrl="assets/w-ui-angular/images/add.svg"
          arrowButtonIcon="arrow-chevron-down"
          [themeColor]="themeColor"
          (buttonClick)="actionClickHandler($event, headerAction.action)"
          [data]="menuItems"
        >
          {{ headerAction.title }}
        </kendo-splitbutton>
      </div>
    </kendo-card-body>
  </kendo-card>
  <ng-template #buttonRef>
    <williams-ui-platform-button
      *ngIf="headerAction?.title !== ''"
      [classList]="buttonClass"
      [matIcon]="buttonIcon"
      (clicked)="actionClickHandler($event, headerAction.action)"
    >
      {{ headerAction.title }}
    </williams-ui-platform-button>
  </ng-template>
  