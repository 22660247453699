export enum InvalidDateFormat {
    KEY = 'invalidDateFormat',
    ERROR_MESSAGE = 'Invalid date/format',
    MIN_YEAR = 1900,
    MONTH_DAY_YEAR = 'month/day/year',
    MONTH_YEAR = 'month/year',
    INVALID_DATE = 'Invalid Date',
    MONTH = 'month',
    DAY = 'day',
    YEAR = 'year'
}

