<div [ngClass]="{ 'show-errors': showErrorMsg }">
  <kendo-textarea
    resizable="auto"
    [rounded]="rounded"
    [maxlength]="maxlength"
    (valueChange)="onValueChange($event)"
    [resizable]="resizable"
    [rows]="rows"
    [formControl]="control"
  >
  </kendo-textarea>
  <kendo-stacklayout [align]="!showErrorMsg ? {horizontal:'end'}:{horizontal:'stretch'}">
    <div *ngIf="showErrorMsg" class="w-font-italic w-mt-1">
      {{errorLabel}}
    </div>
    <kendo-textarea-suffix *ngIf = "showCharacterCount" class="w-font-italic w-color-compTxt-1 w-mt-1">
      <span class="counter">{{ counter }}</span>
    </kendo-textarea-suffix>
  </kendo-stacklayout>
  </div>