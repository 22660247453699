export interface BaseResponse<T = any> {
    data: T;
    status: string;
    success: string;
    others?: Map<string, any>;
    errorMessage?: Map<string, any>;
}
export class ResponseStatus {
    static readonly SUCCESS: string = "OK";
    static readonly INFO: string = "INFO";
    static readonly WARNING: string = "WARNING";
    static readonly ERROR: string = "ERROR";

    static getStatusStyle(respStatus: string): 'none' | 'success' | 'warning' | 'error' | 'info'{
        let style: 'none' | 'success' | 'warning' | 'error' | 'info';

        switch (respStatus){
            case ResponseStatus.ERROR:
                style = "error";
                break;
            case ResponseStatus.INFO:
                style = "info";
                break;
            case ResponseStatus.WARNING:
                style = "warning";
                break;
            case ResponseStatus.SUCCESS:
                style = "success";
                break;
            default:
                style = "none";
        }
        return style;
    }
}