import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-category-axis-item',
  templateUrl: './category-axis-item.component.html',
  styleUrls: ['./category-axis-item.component.scss']
})
export class CategoryAxisItemComponent implements OnInit {
  @Input() categories!:any;
  @Input() title!:any;
  @Input() labels!:any;

  constructor() { }

  ngOnInit(): void {
  }

}
