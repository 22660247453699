import { formatNumber } from "@angular/common";

/***
 * Used for formatting number, format is basically the locale code
 */
export const numberFormatter = (val: any, format: string = "en_CA"): any => {
  if(val){
    return formatNumber(val?.toString(), format);
  }
  return val;
};
