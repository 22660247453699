import { Directive, AfterViewInit, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[wAutoFocus]"
})
export class AutoFocusDirective implements AfterViewInit {
  focusedArr = [
    "WILLIAMS-UI-PLATFORM-MULTISELECT",
    "WILLIAMS-UI-PLATFORM-MULTI-COLUMN-COMBOBOX",
    "WILLIAMS-UI-PLATFORM-TEXT-INPUT",
    "WILLIAMS-UI-PLATFORM-BUTTON"
  ];
  @Input() autoFocusElementRef = "";

  constructor(private _el: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const tagName = this._el.nativeElement.tagName;
      if (this.focusedArr.includes(tagName)) {
        this._el.nativeElement.querySelector("button")?.focus();
        this._el.nativeElement.querySelector("input")?.focus();
      } else {
        this._el.nativeElement.querySelector(this.autoFocusElementRef)?.focus();
      }
    }, 50);
  }
}
