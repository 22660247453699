import { Component, ContentChild, EventEmitter, forwardRef, Input, Output, SimpleChanges } from "@angular/core";
import { ButtonFillMode, ButtonRounded, ButtonSize, ButtonThemeColor, PopupSettings, PreventableEvent } from "@progress/kendo-angular-buttons";
import { DropdownButtonTemplateDirective } from "./directives/dropdown-button-template.directive";
import { moreVerticalIcon, SVGIcon } from "@progress/kendo-svg-icons";

@Component({
  selector: 'williams-ui-platform-dropdown-button',
  templateUrl: './dropdown-button.component.html'
})
export class DropdownButtonComponent {
  // The CSS classes that will be rendered on the main button.
  @Input() buttonClass = '';

  @Input() data: any[] = [];
  
  @Input() disabled = false;
  
  @Input() fillMode: ButtonFillMode = 'clear';

  @Input() icon = '';

  @Input() iconClass = '';

  @Input() imageUrl = '';

  @ContentChild(forwardRef(() => DropdownButtonTemplateDirective)) dropdownTemplateDirective!: DropdownButtonTemplateDirective;

  @Input() popupSettings: PopupSettings = {};

  @Input() rounded: ButtonRounded = 'none';

  @Input() size: ButtonSize = 'medium';

  // Sets the data item field that represents the item text. If the data contains only primitive values, do not define it.
  @Input() textField = '';

  @Input() themeColor: ButtonThemeColor = 'primary';


  // Fires each time the popup is about to close. This event is preventable. If you cancel the event, the popup will remain open.
  @Output() close = new EventEmitter<PreventableEvent>();
  
  // Fires each time the user clicks on a drop-down list item. The event data contains the data item bound to the clicked list item.
  @Output() itemClick	= new EventEmitter<any>();

  // Fires each time the DropDownButton gets blurred.
  @Output() blur	= new EventEmitter<any>();

  // Fires each time the DropDownButton gets focused.
  @Output() focus	= new EventEmitter<any>();

  // Fires each time the popup is about to open. This event is preventable. If you cancel the event, the popup will remain closed.
  @Output() open = new EventEmitter<PreventableEvent>();

  @Input() svgIcon!:SVGIcon;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['icon']) {
      this.updateSvgIcon();
    }
  }

  updateSvgIcon() {
    if(this.icon == "more-vertical") {
      this.svgIcon = moreVerticalIcon;
    }
  }

  
  onClose(event: PreventableEvent): void {
    this.close.emit(event);
  }

  onItemClick(event: any): void {
    this.itemClick.emit(event);
  }

  onBlur(): void {
    this.blur.emit();
  }

  onFocus(): void {
    this.focus.emit();
  }

  onOpen(event: PreventableEvent): void {
    this.open.emit(event);
  }
}