import { Component, ContentChild, forwardRef } from "@angular/core";
import { DialogContentTemplateDirective } from "./dialot-content-templates.directive";
import { DialogContentBase } from "@progress/kendo-angular-dialog";

@Component({
  selector: "williams-ui-platform-dialog-content",
  templateUrl: "./dialog-content.component.html",
})
export class DialogContentComponent extends DialogContentBase {
  @ContentChild(forwardRef(() => DialogContentTemplateDirective))
  dialogTemplates!: DialogContentTemplateDirective;
}
