import { AfterViewInit, Component, ElementRef, forwardRef, Input, Optional, ViewChild, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropDownFillMode, DropDownRounded, DropDownSize, PopupSettings } from '@progress/kendo-angular-dropdowns';
import { Subject, takeUntil } from 'rxjs';
import { calculateTextWidth } from '../../../utils/text-width';
import { BaseAccessor } from '../base/base-accessor';

const CHIP_STYLES: {[key in DropDownSize] : { padding: number, fontSize: number }} = {
  small: {
    padding: 19,
    fontSize: 12
  },
  medium: {
    padding: 11,
    fontSize: 12
  },
  large: {
    padding: 27,
    fontSize: 14
  },
  none: {
    padding: 19,
    fontSize: 12
  }};

const CLEAR_BUTTON_WIDTH = 32;
const SEARCH_INPUT_WIDTH = 36;
const CHIP_GAP = 3;
  
@Component({
  selector: 'williams-ui-platform-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true
    }
  ],
})
export class MultiselectComponent extends BaseAccessor implements AfterViewInit, OnDestroy  {
  @ViewChild("wrapper", { static: false }) wrapper!: ElementRef;

  public isChecked = false;
  @Input() showSelectAll: boolean = false;
  @Input() containerWidth!: string;
  @Input() selectAllText! : string;
  @Input() data: any[] = [];
  @Input('data')
  set in(val: any) {
    this.dropDownData = val;
    this.data = val;
  }
  @Input() autoClose: boolean = false;
  @Input() valueField!: string;
  @Input() filterable:boolean = true;
  @Input() textField!: string;
  @Input() value!:any[];
  @Input() valuePrimitive = true;
  @Input() size:DropDownSize= "medium";
  @Input() popupSettings:PopupSettings={
      width:100,
      popupClass:"w-multiselect"
  };
  @Input() summaryTagCount: number = 2;
  dropDownData:any;
  @Input()
  placeholder:string = 'Select';
  @Input() showErrors = false;
  hideSearchBar = false;
  @Input() fillMode: DropDownFillMode = 'solid';
  @Input() rounded: DropDownRounded = 'none';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() elementId!: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();


  private destroy$ = new Subject<void>();

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }

  get isSelectAllChecked(): boolean {
    return this.control && (this.control.value?.length == this.data?.length);
  }

  ngAfterViewInit(): void {

    if(this.control) {
      this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any[]) => {
        if(!value) {
          return;
        }
        const componentWidth = this.wrapper.nativeElement.offsetWidth;
        if(value.length < 2) {
          this.summaryTagCount = 1;
          return;
        }

        let availableWidth = componentWidth - CLEAR_BUTTON_WIDTH - SEARCH_INPUT_WIDTH - 8;
        const chipPadding = CHIP_STYLES[this.size].padding;
        const font = `400 ${CHIP_STYLES[this.size].fontSize}px Helvetica`;

        const firstSelectionText = this.valuePrimitive ? value[0] : value[0][this.textField];
        availableWidth = availableWidth - (calculateTextWidth(firstSelectionText, font) + chipPadding + CHIP_GAP);
        let count = 1;

        const summarytagWidth = this._getSummaryTagWidth(value);
        
        for(let i=1; i<value.length; i++) {
          const text = this.valuePrimitive ? value[i] : value[i][this.textField];
          const width = calculateTextWidth(text, font) + chipPadding + CHIP_GAP;

          const isLastitem = i === (value.length - 1);
          const totalConsumableWidth = width + (isLastitem ? 0 : summarytagWidth); 
          if (availableWidth > totalConsumableWidth) {
            count++;
            availableWidth = availableWidth - width;
          } else {
            break;
          }
        };

        this.summaryTagCount = count;

      });
    }
  }
  
  private _getSummaryTagWidth(value: any[]): number {
    let summarytagWidth = 36;
    summarytagWidth = value.length > 10 ? 44 : summarytagWidth;
    summarytagWidth = value.length > 100 ? 50 : summarytagWidth;
    summarytagWidth = value.length > 1000 ? 56 : summarytagWidth;
    return summarytagWidth;
  } 

  handleFilter(value:any) {
     this.data = this.dropDownData.filter(
      (s:any) => this.textField ? s[this.textField].toLowerCase().indexOf(value.toLowerCase()) !== -1 : 
                  s.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  getTooltipTextForGroupTag(items: any[]): string {
    return items.map(item => item[this.textField] ?? item).join(', ')
  }
  
  public onClick(value:any) {
    this.isChecked = !this.isChecked;
    this.value = (this.isChecked) ? this.valuePrimitive ? this.data.map(item => item[this.valueField]): this.data : [];
    this.control.patchValue(this.value)
  }
  public get toggleAllText() {
    return this.selectAllText;
  }
  public onValueChange(value:any) {
    this.isChecked = value.length === this.data.length;
    this.valueChange.emit(value);
  }
  public isItemSelected(itemText: string): boolean {
    return this.value.some(item => item.text === itemText);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
