import { Component, ContentChild, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { SeriesItemTooltipComponent } from './series-item-tooltip/series-item-tooltip.component';
import { SeriesItemComponent } from './series-item/series-item.component';

@Component({
  selector: 'williams-ui-platform-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesComponent implements OnInit {

  @ContentChildren(SeriesItemComponent)
  seriesItems!: QueryList<SeriesItemComponent>;
  @ContentChild(SeriesItemTooltipComponent) seriesItemTooltipComponent!: SeriesItemTooltipComponent;
  
  constructor() { }

  ngOnInit(): void {
  }


}
