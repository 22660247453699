import { Injectable } from "@angular/core";
import { Subscription, interval } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class IntervalTimerService {
  private timerSubscription!: Subscription;
  constructor() {}

  startTimer(intervalTime: number, callback: () => void) {
    this.timerSubscription = interval(intervalTime).subscribe(() => {
      callback();
    });
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
