<div [formGroup]="formGroup" class="d-flex flex-align-center" [ngClass]="{ 'show-errors': showErrors }"
>
<ng-container *ngIf="!elementId; else radioBtn">
  <input
    type="radio"
    [name]="name"
    [attr.name]="name"
    [value]="value"
    [formControl]="control"
    [checked]="checked"
    [attr.disabled]="disabled ? 'disabled' : null"
    [size]="size"
    kendoRadioButton
    #radio
  />
  <kendo-label [for]="radio" [text]="label" [class]="labelClass"></kendo-label>
</ng-container>
</div>


<ng-template #radioBtn>
  <input
  type="radio"
  [name]="name"
  [value]="value"
  [formControl]="control"
  [checked]="checked"
  [attr.disabled]="disabled ? 'disabled' : null"
  [size]="size"
  [id]="elementId"
  kendoRadioButton
  #radio
/>
<kendo-label [for]="radio" [text]="label" [class]="labelClass"></kendo-label>
</ng-template>