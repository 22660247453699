import { getFieldValueFromDataItemRecursively } from "./helpers";

/**
 * Searches the record list to find any duplicates
 * 
 * @param records All the records in grid
 * @param editedRecords Records added or updated by user
 * @param compareKeys Keys to compare
 * @returns An array of length two containing indexes where a record is duplicated, an empty array if no duplciates are found.
 */
export const findDuplicates = (records: any[], editedRecords: any[], compareKeys: { key: string; type?: "string" | "number" | "date", caseSensitive?: boolean, editedKey?:string, format?: string }[],  isGridUpdated = true): number[] => {
  for(let editedRecord of editedRecords) {
    const result = [];
    for(let [index, record] of records.entries()) {
      const isDuplicate = compareKeys.every(item => {
        const keyType = item.type ?? "string";
        const editedRecordValue = getFieldValueFromDataItemRecursively(editedRecord, item.editedKey? item.editedKey: item.key);
        const recordValue = getFieldValueFromDataItemRecursively(record, item.key);

        if(keyType == "date") {
          return item.format ? compareDate(editedRecordValue,recordValue,item.format) : (editedRecordValue as Date).getTime() == (recordValue as Date).getTime();
          // return val;
        }
        
        if(item.caseSensitive == false) {
          return editedRecordValue.toLowerCase() == recordValue.toLowerCase();
        }

        return editedRecordValue == recordValue;
      });

      if(isDuplicate) {
        result.push(index);
        if(result.length == 1 && !isGridUpdated) {
          return result;
        }
        if(result.length == 2 && isGridUpdated) {
          return result;
        }
      }
    }
  }
  return [];
}

function compareDate(date1: Date, date2: Date,format?: string) {
  if(date1 && date2) {
  if(format === "MM/dd/yyyy"){
    date1.setHours(0,0,0,0);
    date2.setHours(0,0,0,0);
    return date1.getTime() === date2.getTime();
  }
  const month1 = date1.getMonth();
  const year1 = date1.getFullYear();
  const month2 = date2.getMonth();
  const year2 = date2.getFullYear();
  const newDate1 = new Date(`${month1}/1/${year1}`);
  const newDate2 = new Date(`${month2}/1/${year2}`);

  return newDate1.getTime() === newDate2.getTime();
}
return false;
}