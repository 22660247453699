import { ContentChild, Directive, TemplateRef } from "@angular/core";

@Directive({
    selector: 'williams-ui-platform-dropdown-template'
  })
  export class DropdownButtonTemplateDirective {
    @ContentChild('iconTemplate') iconTemplate!: TemplateRef<any>;
  
    constructor() { }
  }
  