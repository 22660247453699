import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'williams-ui-platform-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
   @Input() width: number = 600;
   @Input() top: number = 56;
   height: number = 500;
   @Input() bottom : number = 0 ;
   

  constructor() { }

  ngOnInit(): void {
    this.height=window.innerHeight- this.top - this.bottom;
  }

}
