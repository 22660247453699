import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ButtonFillMode, ButtonThemeColor} from "@progress/kendo-angular-buttons/common/models";

export interface ButtonOption {
    text: string;
    value: string;
    clicked?: Function;
    fillMode?: ButtonFillMode;
    icon?: string;
    themeColor?: ButtonThemeColor;
    disabled?: boolean;
    selected?: boolean;
}
@Component({
    selector: 'williams-ui-platform-button-group',
    templateUrl: './button-group.component.html',
    styleUrls:['button-group.component.scss']
})
export class ButtonGroupComponent {
    @Input() buttons: Array<ButtonOption> = [];
    @Output() clicked = new EventEmitter<ButtonOption>();
    onButtonClick(option: ButtonOption){
        if(typeof option.clicked == 'function'){
            option.clicked.call(this, option);
        }
        this.clicked.emit(option);
    }
}