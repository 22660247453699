<kendo-listview
  [data]="data"
  [height]="height"
  [itemClass]="itemClass"
  [itemStyle]="itemStyle"
  [containerClass]="containerClass"
  [containerStyle]="containerStyle"
  [ngClass]="[cssClass, striped ? 'striped' : '' ]"
>
  <ng-template kendoListViewHeaderTemplate *ngIf="listViewHeader">
    <ng-container
      *ngTemplateOutlet="listViewHeader.headerTemplate"
    ></ng-container>
  </ng-template>
  <ng-template
    kendoListViewItemTemplate
    let-dataItem
    let-index="index"
    let-isFirst="isFirst"
    let-isLast="isLast"
    *ngIf="listViewItem"
  >
    <ng-container
      *ngTemplateOutlet="
        listViewItem.itemTemplate;
        context: { $implicit: dataItem, dataItem, index, isFirst, isLast }
      "
    ></ng-container>
  </ng-template>
  <ng-template kendoListViewFooterTemplate *ngIf="listViewFooter">
    <ng-container
      *ngTemplateOutlet="listViewFooter.footerTemplate"
    ></ng-container>
  </ng-template>
</kendo-listview>
