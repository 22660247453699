import {
    Component, forwardRef, Input, Optional
} from "@angular/core";
import { ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputSize } from "@progress/kendo-angular-inputs";
import { BaseAccessor } from "@williams-ui-platform/w-ui-angular";


@Component({
    selector: 'williams-ui-platform-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioButtonComponent),
            multi: true
        }
    ]
})
export class RadioButtonComponent extends BaseAccessor {
    @Input() labelClass = 'w-mr-4 w-ml-2 w-base-text';
    @Input() value: string = '';
    @Input() name: string = '';
    @Input() label: string = '';
    @Input() checked: boolean = false;
    @Input() disabled: boolean = false;
    @Input() size: InputSize = 'medium';
    @Input() showErrors = false;
    @Input() elementId!: string;
    constructor(@Optional() public override controlContainer: ControlContainer) {
        super(controlContainer);
    }

}
