<div class="tab-list-wrapper d-flex flex-column">
  <div class="william-overview-details d-flex flex-justify-center">
    <div class="tabs">
      <kendo-buttongroup selection="single">
        <button *ngFor="let tab of tabs; index as i" kendoButton [toggleable]="true" [routerLink]="tab.routeName"
          routerLinkActive="k-selected">
          {{ tab.title }}
        </button>
      </kendo-buttongroup>
    </div>
    <div>
      <ng-content>
      </ng-content>
    </div>
  </div>
  <div class="flex-1">
    <router-outlet></router-outlet>
  </div>
</div>
