import { Component, ContentChild, Directive, ElementRef, EventEmitter, forwardRef, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import {
  ButtonFillMode,
  ButtonRounded,
  ButtonSize,
  ButtonThemeColor,
  SplitButtonComponent as KendoSplitButtonComponent
} from "@progress/kendo-angular-buttons";
import { SVGIcon } from "@progress/kendo-svg-icons";


@Component({
  selector: "williams-ui-platform-splitbutton",
  templateUrl: "./splitbutton.component.html",
})
export class SplitButtonComponent {
  @Input() text!: string;
  @Input() data: any[] = [];
  @Input() icon!: string;
  @Input() type = "button";
  @Input() matIcon!: string;
  @Input() imageUrl!: string;
  @Input() arrowButtonIcon: string = "caret-alt-down";
  @Input() size: ButtonSize = "medium";
  @Input() fillMode: ButtonFillMode = "outline";
  @Input() rounded: ButtonRounded = "none";
  @Input() themeColor: ButtonThemeColor = "primary";
  @Input() disabled: boolean = false;
  @Input() mantIconClassName:string = "";
  @Output() btnClick: EventEmitter<void> = new EventEmitter();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() itemClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() blurEvent: EventEmitter<any> = new EventEmitter();
  @Input() svgIcon!:SVGIcon;

  @ViewChild("kendoSplitButtonInstance") public kendoSplitButtonInstance!: KendoSplitButtonComponent ;

  @ContentChild(forwardRef(() => SplitButtonIconTemplateDirective)) splitButtonTemplateDirective!: SplitButtonIconTemplateDirective;

  close(event:any){
    this.closeEvent.emit({event})
  }
  itemClicked(event:any){
    this.itemClickEvent.emit(event)
  }
  blurEventCalled(event:any){
    this.blurEvent.emit(event)
  }
}

@Directive({
  selector: 'williams-ui-platform-icon-template'
})
export class SplitButtonIconTemplateDirective {
  @ContentChild('iconTemplate') iconTemplate!: TemplateRef<any>;

  constructor() { }

 
}
