<kendo-upload
  [saveUrl]="saveUrl"
  [removeUrl]="removeUrl"
  saveField="file"
  [saveHeaders]="saveHeaders"
  [multiple]="false"
  [restrictions]="restrictions"
  [autoUpload]="autoUpload"
  (valueChange)="onValueChange($event)"
  (success)="onSuccess($event)"
  (error)="onError($event)"
>
</kendo-upload>
