<kendo-drawer-container>
    <kendo-drawer #drawer
        [class]="themeClass"
        [animation]="animation"
        [autoCollapse]="autoCollapse"
        [expanded]="expanded"
        [isItemExpanded]="isItemExpanded"
        [items]="items"
        [mini]="mini"
        [miniWidth]="miniWidth"
        [mode]="mode"
        [position]="position"
        [width]="width"
        (collapse)="onCollapse($event)"
        (expand)="onExpand($event)"
        (expandedChange)="onExpandedChange($event)"
        (select)="onSelect($event)">
        <ng-template kendoDrawerHeaderTemplate *ngIf="drawerTemplates?.headerTemplate">
            <ng-container
                    *ngTemplateOutlet="drawerTemplates.headerTemplate"
            ></ng-container>
        </ng-template>
        <ng-template kendoDrawerItemTemplate *ngIf="drawerTemplates?.itemTemplate" 
                     let-item 
                     let-level="level" 
                     let-hasChildren="hasChildren"
                     let-isItemExpanded="isItemExpanded"
        >
            <ng-container
                *ngTemplateOutlet="drawerTemplates.itemTemplate;context: { $implicit: item, item, level, hasChildren, isItemExpanded }" 
            ></ng-container>
        </ng-template>
        <ng-template kendoDrawerFooterTemplate *ngIf="drawerTemplates?.footerTemplate">
            <ng-container
                    *ngTemplateOutlet="drawerTemplates.footerTemplate"
            ></ng-container>
        </ng-template>
        <ng-template kendoDrawerTemplate *ngIf="drawerTemplates?.drawerTemplate">
            <ng-container
            *ngTemplateOutlet="drawerTemplates.drawerTemplate"
            ></ng-container>
        </ng-template>
    </kendo-drawer>
    <kendo-drawer-content [style]="{ 'padding-left': mini ? miniWidth + 'px' : '0' }" [classList]="contentClass">
            <ng-content></ng-content>
    </kendo-drawer-content>
</kendo-drawer-container>
