import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Optional,
  Output,
} from "@angular/core";
import { ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputRounded, InputSize } from "@progress/kendo-angular-inputs";
import { BaseAccessor } from "../base/base-accessor";

@Component({
  selector: "williams-ui-platform-switch",
  templateUrl: "./switch.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent extends BaseAccessor {
  @Input() gridReadonlyView = false;
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() offLabel!: string;
  @Input() onLabel!: string;
  @Input() readonly: boolean = false;

  // Specifies the width and height of the Switch.
  @Input("size") set _size(value: InputSize) {
    this.size = value;
    this._setSizeClass(value);
  }

  // Specifies the border radius of the Switch thumb.
  @Input() thumbRounded: InputRounded = "full";

  // Specifies the border radius of the Switch track.
  @Input() trackRounded: InputRounded = "full";

  // Fires each time the input element gets blurred.
  @Output() blur = new EventEmitter();

  // Fires each time the user focuses the input element.
  @Output() focus = new EventEmitter();

  // Fires each time the user selects a new value.
  @Output() valueChange = new EventEmitter();

  sizeClass = "k-switch-md";

  size: InputSize = "medium";

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }

  private _setSizeClass(value: InputSize): void {
    let result = "k-switch-";
    switch (value) {
      case "small":
        result += "sm";
        break;
      case "medium":
        result += "md";
        break;
      case "large":
        result += "lg";
        break;
    }
    this.sizeClass = result;
  }

  onBlur(event: any) {
    this.blur.emit(event);
  }

  onFocus(event: any) {
    this.focus.emit(event);
  }

  onValueChange(event: any) {
    this.valueChange.emit(event);
  }
}
