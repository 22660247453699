<form [formGroup]="searchForm" *ngIf="searchForm">
  <williams-ui-platform-text-input 
   formControlName="searchTerm" 
   [placeholder]="placeholder"
   [clearButton]="clearButton" >
  <williams-ui-platform-text-input-template>
    <ng-template #textInputSuffixTemplate>
      <mat-icon>search</mat-icon>
      </ng-template>
  </williams-ui-platform-text-input-template>
  </williams-ui-platform-text-input>
</form>
