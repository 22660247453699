import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { formatDate, formatNumber } from "@progress/kendo-angular-intl";
import { numberFormatter } from "../../utils";
import { ColumnConfiguration } from "../models/column";
import { getFieldValueFromDataItemRecursively } from "../utils";
@Component({
  selector: "williams-ui-platform-grid-cell",
  templateUrl: "./grid-cell.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridCellComponent {
  @Input() columnConfiguration!: ColumnConfiguration;
  @Input() dataItem: any;
  @Output() onDoubleClick = new EventEmitter<void>();
  get fieldValue(): string {
    let value = getFieldValueFromDataItemRecursively(this.dataItem, this.columnConfiguration.field);
    if(this.columnConfiguration.numberFormat?.required){
      return numberFormatter(value)
    }
    if(value instanceof Date && this.columnConfiguration.format) {
      return formatDate(value, this.columnConfiguration.format);
    }
    if(value != null && `${value}` != "" && this.columnConfiguration.format){
      return formatNumber(`${value}`, this.columnConfiguration.format);
    }
    return value;
  }

  onDbClick() {
    this.onDoubleClick.emit();
  }
}
