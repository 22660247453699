<div class="button-area" *ngIf="actionButtons">
    <button kendoButton class="k-button k-secondary prev" [disabled]="currentStep === 0" (click)="buttonClick('prev')"> Back</button>
    <button kendoButton class="k-button k-primary next" (click)="buttonClick('next')" *ngIf="currentStep !== stepsArr.length-1">Continue </button>
    <button kendoButton class="k-button k-primary next" (click)="buttonClick('submit')" *ngIf="currentStep === stepsArr.length-1">Submit</button>
</div>
<kendo-stepper [steps]="stepsArr" [stepType]="'label'" [(currentStep)]="currentStep"
    (currentStepChange)="stepClick($event)">
    <ng-template kendoStepperLabelTemplate *ngIf="stepperLabelTemp?.labelTemplate" let-step>
        <ng-container *ngTemplateOutlet="stepperLabelTemp.labelTemplate;context: { step }"></ng-container>
    </ng-template>
</kendo-stepper>