<div [ngClass]="{ 'show-errors': showErrors }">
    <kendo-maskedtextbox
    class="mt-4"
    [formControl]="control"
    [readonly]="readonly"
    [includeLiterals]="includeLiterals"
     [mask]="mask"
     [(value)] = value
     [fillMode]="fillMode"
     [size]="size"
     [rounded]="rounded"
     [prompt]="prompt"
     [promptPlaceholder]="promptPlaceholder"
     [maskOnFocus]="maskOnFocus"
     >
    </kendo-maskedtextbox>
    <kendo-icon class="requiredIcon w-icon-color w-ml-n6" *ngIf="showErrors" name="warning"></kendo-icon>
</div>