<kendo-numerictextbox
 [spinners]="spinners"
 [formControl]="control"
 [decimals]="decimals"
 [format]="format"
 [value]="value"
 [fillMode]="fillMode"
 [size]="size"
 [rounded]="rounded"
 [min]="min"
 [max]="max"
 [autoCorrect]="false"
 [changeValueOnScroll]="changeValueOnScroll"
 [disabled]="disabled"
 [placeholder]="placeholder"
 [rangeValidation]="rangeValidation"
 [readonly]="readonly"
 [selectOnFocus]="selectOnFocus"
 [step]="step"
 [tabindex]="tabindex"
 [title]="title"
 (valueChange)="onValueChange($event)"
 (blur)="onBlur($event)"
 (focus)="onFocus($event)"
 [maxlength]="maxLength"
>
</kendo-numerictextbox>