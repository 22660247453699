import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ColumnConfiguration } from "../models";
import { DEFAULT_COLUMN_CONFIGURATION } from "../constants/default-values";

@Component({
  selector: 'williams-ui-platform-grid-cell-edit',
  templateUrl: './grid-cell-edit.component.html'
})
export class GridCellEditComponent {
  @Input() columnConfiguration!: ColumnConfiguration;
  @Input() group!: FormGroup;
  numericEditorSettings:any = DEFAULT_COLUMN_CONFIGURATION['numericEditorSettings'];
}