import { Component, ElementRef, forwardRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { Position, ShowOption, TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'williams-ui-platform-tooltip',
  templateUrl: './tooltip.component.html'
})
export class ToolTipComponent{
  @Input() tooltipTemplate!: TemplateRef<any>;
  @Input() titleTemplate!: TemplateRef<any>;
  @Input() showOn : ShowOption = 'hover';
  @Input() position: Position = 'top';
  @Input() closable : boolean = false;
  @Input() callout : boolean = true;
  @Input() offset! : number;
  @Input() closeTitle!: string;
  @Input() showAfter!: number;
  @Input() tooltipClass!: string;
  @Input() tooltipHeight!: number;
  @Input() tooltipWidth :number = 250;
  @Input() filter!:string;

  @ViewChild(forwardRef(() => TooltipDirective)) tooltipDirective!: TooltipDirective;

  constructor() {  }

  public show(anchor: Element | ElementRef<any>){
    this.tooltipDirective.show(anchor);
  }
  public hide(){
    this.tooltipDirective.hide();
  }
  public toggle(anchor: Element | ElementRef<any>, show?:boolean){
    this.tooltipDirective.toggle(anchor,show);
  }

}
