<div class="d-flex" [ngClass]="{'show-errors': showErrorMsg}">
    <kendo-timepicker
            [formControl]="control"
            [disabled]="disabled"
            [readonly]="readOnly"
            [readOnlyInput]="readOnlyInput"
            [cancelButton]="cancelButton"
            [fillMode]="fillMode"
            [format]="format"
            [formatPlaceholder]="formatPlaceholder"
            [incompleteDateValidation]="incompleteDateValidation"
            [max]="max"
            [min]="min"
            [nowButton]="nowButton"
            [placeholder]="placeholder"
            [rangeValidation]="rangeValidation"
            [rounded]="rounded"
            [size]="size"
            [steps]="steps"
            [title]="title"
            [(value)]="value"
            [class]="classList"
            (valueChange)="valueChangeHandler($event)"
    ></kendo-timepicker>
</div>