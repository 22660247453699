import {Component, Input, OnInit, Pipe, PipeTransform} from "@angular/core";
import {Router} from "@angular/router";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

export interface ErrorEntity {
  id: any;
  title: string;
  errors: {
      code?: string;
      id?: string;
      message: string
  }[];
  type?: any;
}

@Component({
  selector: "williams-ui-platform-error-messages",
  templateUrl: "./error-messages.component.html",
})
export class ErrorMessagesComponent {
  @Input() errorEntity!: ErrorEntity;
  @Input() errorLink!: string;
  // If we need error flag false then we need to pass it from parent else it will give regression to component developed
  @Input() isError: boolean = true;
}

@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    transform(value: any): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}

