import { Component, EventEmitter, forwardRef, Input, Optional, Output } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckBoxRounded, InputSize } from '@progress/kendo-angular-inputs';
import {BaseAccessor} from "@williams-ui-platform/w-ui-angular";

@Component({
    selector: 'williams-ui-platform-indeterminate-checkbox',
    templateUrl: './indeterminate-checkbox.component.html',
    styleUrls: ['../checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IndeterminateCheckboxComponent),
            multi: true
        }
    ]
})
export class IndeterminateCheckboxComponent extends BaseAccessor {
    @Input() size: InputSize = 'medium';
    @Input() rounded: CheckBoxRounded = 'medium';
    @Input() checked! : boolean;
    @Input() isDisabled : boolean =false;
    @Input() indeterminate: boolean = false;
    @Input() elementId!:string;
    @Output() checkboxChange = new EventEmitter<any>();

    constructor(@Optional() public override controlContainer: ControlContainer) {
        super(controlContainer);
    }
    public onClick() {
        this.checked = !this.checked;
        this.checkboxChange.emit(this.checked);
    }
}