import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { WindowState } from "@progress/kendo-angular-dialog";
import { WindowTitlebarDirective } from "./directives/window-titlebar.directive";

@Component({
  selector: "williams-ui-platform-window",
  templateUrl: "./window.component.html",
  styleUrls: ["./window.component.scss"],
})
export class WindowComponent implements OnInit {
  @Input() width: number = 600;
  @Input() height: number = 500;
  @Input() minHeight: number = 100;
  @Input() minWidth: number = 120;
  @Input() left! : number ;
  @Input() top!: number ;
  @Input() title: string = "";
  @Input() draggable: boolean = true;
  @Input() keepContent: boolean = true;
  @Input() resizable: boolean = false;
  @Input() state: WindowState = "default";

  // Fires after the popup has been opened.
  @Output() opened: EventEmitter<any> = new EventEmitter();
  // Fires after the popup has been closed.
  @Output() closed: EventEmitter<any> = new EventEmitter();

  //Fires when the Window was moved by the user.
  @Output() dragEnd: EventEmitter<any> = new EventEmitter();
  //Fires when the user starts to move the Window
  @Output() dragStart: EventEmitter<any> = new EventEmitter();
  //Fires when the height property of the component was updated
  @Output() heightChange: EventEmitter<number> = new EventEmitter();
  //Fires when the left property of the component was updated
  @Output() leftChange: EventEmitter<number> = new EventEmitter();
  //Fires when the Window was resized by the user
  @Output() resizeEnd: EventEmitter<any> = new EventEmitter();
  //Fires when the user starts to resize the Window
  @Output() resizeStart: EventEmitter<any> = new EventEmitter();
  //Fires when the state property of the component was updated
  @Output() stateChange: EventEmitter<any> = new EventEmitter();
  //Fires when the top property of the component was updated
  @Output() topChange: EventEmitter<number> = new EventEmitter();
  //Fires when the width property of the component was updated
  @Output() widthChange: EventEmitter<number> = new EventEmitter();

  @ContentChild(forwardRef(() => WindowTitlebarDirective))
  windowTitlebarDirective!: WindowTitlebarDirective;
  constructor() {}

  ngOnInit(): void {}

  onOpened(event: any): void {
    this.opened.emit(event);
  }
  onClosed(event: any): void {
    this.closed.emit(event);
  }
  onDragEnd(event: any): void {
    this.dragEnd.emit(event);
  }
  onDragStart(event: any): void {
    this.dragStart.emit(event);
  }
  onHeightChange(event: number): void {
    this.heightChange.emit(event);
  }
  onLeftChange(event: number): void {
    this.leftChange.emit(event);
  }
  onResizeEnd(event: any): void {
    this.resizeEnd.emit(event);
  }
  onResizeStart(event: any): void {
    this.resizeStart.emit(event);
  }
  onStateChange(event: any): void {
    this.stateChange.emit(event);
  }
  onTopChange(event: number): void {
    this.topChange.emit(event);
  }
  onWidthChange(event: number): void {
    this.widthChange.emit(event);
  }
}
