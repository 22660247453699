import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-series-item',
  templateUrl: './series-item.component.html',
  styleUrls: ['./series-item.component.scss']
})
export class SeriesItemComponent implements OnInit {

  @Input() type!:any;
  @Input() data!:any;
  @Input() field!:string;
  @Input() categoryField!:any;
  @Input() color = "#0E757D";
  
  constructor() { }

  ngOnInit(): void {
  }

}
