 export const addRowNumberForInsert = (record:any, records:any[], index:number,key:string="rowNumber")=> {
    return {
      ...record,
      [key] : (records.length - index)
    }
}
  
  export const addRowNumberForUpdate = ( gridData:any[], record:any,config:{recordKey:string,payloadKey:string}={recordKey:'id',payloadKey:'rowNumber'})=>{
    let index = gridData.findIndex( (item:any) => {
      return item[config.recordKey] == record[config.recordKey]
    })
    return {
      ...record,
      [config.payloadKey]: index +1
    }
  }