import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonSize } from "../button";

@Component({
  selector: "williams-ui-platform-paginator",
  templateUrl: "./paginator.component.html",
})
export class PaginatorComponent<T> {
  @Input() pages: T[] = [];
  @Input() currentPage!: T;
  @Input() compareKey!: keyof T;
  @Input() disableAll: boolean = false;
  @Input() size: ButtonSize = "medium";

  @Output() pageChange = new EventEmitter<T>();

  firstPage(): void {
    if (this.pages.length == 0) {
      return;
    }

    const firstPage = this.pages[0];
    if (this._isEqual(firstPage, this.currentPage)) {
      return;
    }

    this.pageChange.emit(firstPage);
  }

  lastPage(): void {
    if (this.pages.length == 0) {
      return;
    }

    const lastPage = this.pages[this.pages.length - 1];
    if (this._isEqual(lastPage, this.currentPage)) {
      return;
    }

    this.pageChange.emit(lastPage);
  }

  nextPage(): void {
    const currentPageIndex = this._getCurrentPageIndex();
    // Return if current page is the last page
    if (currentPageIndex == this.pages.length - 1) {
      return;
    }

    this.pageChange.emit(this.pages[currentPageIndex + 1]);
  }

  previousPage(): void {
    if (this.pages.length == 0) {
      return;
    }

    // Return if current page is the first page
    const currentPageIndex = this._getCurrentPageIndex();
    if (currentPageIndex == 0) {
      return;
    }

    this.pageChange.emit(this.pages[currentPageIndex - 1]);
  }

  private _isEqual(page1: T, page2: T) {
    return page1[this.compareKey] == page2[this.compareKey];
  }

  private _getCurrentPageIndex(): number {
    return this.pages.findIndex((item) => this._isEqual(item, this.currentPage));
  }
}
