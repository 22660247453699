import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  FileInfo,
  FileRestrictions,
  SuccessEvent,
} from "@progress/kendo-angular-upload";
import { HttpHeaders } from "@angular/common/http";

@Component({
  selector: "williams-ui-platform-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent {
  @Input() autoUpload: boolean = true;
  @Input() saveUrl!: string;
  @Input() removeUrl!: string;
  @Input() saveHeaders = new HttpHeaders();

  @Output() fileValueChange = new EventEmitter<any>();
  @Output() success = new EventEmitter<SuccessEvent>();
  @Output() error = new EventEmitter<ErrorEvent>();

  @Input() restrictions: FileRestrictions = {
    allowedExtensions: [".xls", ".xlsx"],
  };

  constructor() {}

  onValueChange(file: FileInfo[]) {
    this.fileValueChange.emit(file);
  }

  onSuccess(value: any) {
    this.success.emit(value);
  }

  onError(value: any) {
    this.error.emit(value);
  }
}
