import { Component, Input } from '@angular/core';

@Component({
    selector: 'williams-ui-platform-form-messages',
    templateUrl: './form-message.component.html',
    styleUrls: ['form-message.component.scss']
})
export class FormMessageComponent {
    @Input() msgTitle: string="";
    @Input() msgs: string[] = [];
    @Input() forError: boolean = true;

    constructor() { }
}
