import { ChangeDetectorRef, Directive, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CheckedState, TreeItemLookup } from '../model';
import { Subscription } from 'rxjs';
import {TreeViewComponent} from "@progress/kendo-angular-treeview";

/**
 * A directive which manages the node in-memory checked state of the TreeView.
 */
@Directive({ selector: '[customCheck]' })
export class CustomCheckDirective implements OnInit, OnDestroy {
    /**
     * @hidden
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Input() public set isChecked(value: (item: Object, index: string) => CheckedState) {
        this.treeView.isChecked = value;
    }

    /**
     * Defines the collection that will store the checked keys.
     */
    @Input() public checkedKeys: any[] = [];

    /**
     * Fires when the `checkedKeys` collection was updated.
     */
    @Output() public checkedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    protected subscriptions: Subscription = new Subscription(() => {/**/ });
    protected resolvedPromise: Promise<any> = Promise.resolve(null);

    constructor(protected treeView: TreeViewComponent, private cdr: ChangeDetectorRef) { }

    public ngOnInit(): void {
        this.subscriptions.add(
            this.treeView.checkedChange
                .subscribe((e) => this.checkMultiple(e))
        );

        this.subscriptions.add(
            this.treeView.childrenLoaded
                .subscribe((e) => {
                    this.resolvedPromise.then(() => {
                        this.addChildrenKeys(e);
                        this.cdr.detectChanges();
                    });
                })
        );

        this.treeView.isChecked = this.isItemChecked.bind(this);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    protected isItemChecked(_: any, index: string): CheckedState {
        if (this.checkedKeys.includes(index)) {
            return 'checked';
        }

        const hasCheckedChildren = this.checkedKeys.some(k => k.startsWith(index + '_'));

        if (hasCheckedChildren) {
            return 'indeterminate';
        }

        return 'none';
    }

    protected checkMultiple(node: TreeItemLookup): void {
        this.checkNode(node);
        this.checkParents(node.parent);
        this.notify();
    }

    private checkNode(node: TreeItemLookup, check?: boolean): void {
        const key = node.item.index;
        const idx = this.checkedKeys.indexOf(key);

        const isChecked = idx > -1;
        const shouldCheck = check === undefined ? !isChecked : check;
        const isKeyPresent = key !== undefined && key !== null;

        if (!isKeyPresent || (isChecked && check)) { return; }

        if (isChecked) {
            this.checkedKeys.splice(idx, 1);
        } else {
            this.checkedKeys.push(key);
        }

        node?.children?.map(n => this.checkNode(n, shouldCheck));
    }

    private checkParents(parent: any): void {
        let currentParent = parent;

        while (currentParent) {
            const parentKey = currentParent.item.index;
            const parentIndex = this.checkedKeys.indexOf(parentKey);

            if (this.allChildrenSelected(currentParent.children)) {
                // if (parentIndex === -1) {
                //     this.checkedKeys.push(parentKey);
                // }
            } else if (parentIndex > -1) {
                this.checkedKeys.splice(parentIndex, 1);
            }

            currentParent = currentParent.parent;
        }
    }

    private allChildrenSelected(children: any[]): boolean {
        const isCheckedReducer = (checked: any, item: any) => (
            checked && this.isItemChecked(item.dataItem, item.index) === 'checked'
        );

        return children.reduce(isCheckedReducer, true);
    }

    private addChildrenKeys(args: any): void {
        if (this.checkedKeys.indexOf(args.item.index) === -1) {
            return;
        }

        const keys = args.children.reduce((acc: any, item: any) => {
            const existingKey = this.checkedKeys.find(key => item.index === key);

            if (!existingKey) {
                acc.push(item.index);
            }

            return acc;
        }, []);

        if (keys.length) {
            this.checkedKeys = this.checkedKeys.concat(keys);
            this.notify();
        }
    }

    private notify(): void {
        this.checkedKeysChange.emit(this.checkedKeys.slice());
    }
}
