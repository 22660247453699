import { TemplateRef } from "@angular/core";
import {GridColumnDef} from "./grid-column-def";

export interface GridColumnDefExt extends GridColumnDef{
    isCheckbox?: boolean;
    textAlign?: 'left'| 'right' | 'center';
    click?: Function;
    format?: any;
    noColumnMenu?: boolean;
    cellTemplate?: TemplateRef<any>;
    // @truc We have done changes due to compilation error as super is not working so added this. Pls plan to revert code with expected fix
}