import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-chart-title',
  templateUrl: './chart-title.component.html',
  styleUrls: ['./chart-title.component.scss']
})
export class ChartTitleComponent implements OnInit {
  @Input() text!: string;
  constructor() { }

  ngOnInit(): void {
  }

}
