import {Component, Input, OnInit} from "@angular/core";
import {ScrollMode} from "@progress/kendo-angular-grid/scrolling/scrollmode";
import {GridColumnDefExt} from "../models/grid-column-def-ext";

@Component({
    selector: 'williams-ui-platform-grid-view-expansion-panel',
    templateUrl: './grid-view-expansion-panel.component.html',
    styleUrls: ['./grid-view-expansion-panel.component.scss']
})
export class GridViewExpansionPanelComponent implements OnInit {
    @Input() summaryHeader: string = '';
    @Input() scrollable: ScrollMode = "none";
    @Input() hideHeader: boolean = true;
    @Input() gridColumnDefs: Array<GridColumnDefExt> = [];
    @Input() data: any[] = [];
    @Input() show: boolean = true;
    @Input() headerTitleWidth?: string;
    ngOnInit() {
    }
    showHide(){
        this.show = !this.show;
    }
}
