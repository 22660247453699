import { ContentChild, Directive, TemplateRef } from "@angular/core";
@Directive({
  selector: "williams-ui-platform-dialog-content-templates",
})
export class DialogContentTemplateDirective {
  @ContentChild("titleTemplate") titleTemplate!: TemplateRef<any>;
  @ContentChild("contentTemplate") contentTemplate!: TemplateRef<any>;
  @ContentChild("actionTemplate") actionTemplate!: TemplateRef<any>;
  constructor() {}
}
