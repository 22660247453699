<williams-ui-platform-pdf-viewer *ngIf="!showHomePage"></williams-ui-platform-pdf-viewer>
<div class="homepage-container" *ngIf="showHomePage">
    <div class="bar">
        <div class="title-text">
            Discovery Gas Transmission LLC
        </div>
        <div class="d-flex align-center">
            <img src="assets/w-ui-angular/images/calendar.svg" alt="" style="margin-right: 8px; width: 13.33px;">
            <div class="w-color-white-1">
                <h6 class="w-small-text-normal w-m-0">
                    {{ date | date: "EEEE" }}
                </h6>
                <p class="w-h1-bold w-m-0">
                    {{ date | date:'longDate' }}
                </p>
            </div>
        </div>
    </div>
    <div class="drawerContainer d-flex flex-row" *ngIf="showHomePage">
        <williams-ui-platform-nav-bar-discovery></williams-ui-platform-nav-bar-discovery>
    </div>
    <div class="mainContainer">
        <div>
            <williams-ui-platform-header></williams-ui-platform-header>
        </div>
        <router-outlet></router-outlet>
    </div>
    <williams-ui-platform-footer></williams-ui-platform-footer>
</div>