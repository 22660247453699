<kendo-dropdownbutton
  [buttonClass]="buttonClass"
  [data]="data"
  [disabled]="disabled"
  [fillMode]="fillMode"
  [icon]="icon"
  [svgIcon]="svgIcon"
  [iconClass]="iconClass"
  [imageUrl]="imageUrl"
  [popupSettings]="popupSettings"
  [rounded]="rounded"
  [size]="size"
  [textField]="textField"
  [themeColor]="themeColor"
  (close)="onClose($event)"
  (itemClick)="onItemClick($event)"
  (blur)="onBlur()"
  (focus)="onFocus()"
  (open)="onOpen($event)"
> 
  <ng-content></ng-content>
  <ng-template kendoDropDownButtonItemTemplate let-dataItem *ngIf="dropdownTemplateDirective?.iconTemplate">
    <ng-container   *ngTemplateOutlet="dropdownTemplateDirective.iconTemplate;context: { dataItem }">
    </ng-container>
  </ng-template>
</kendo-dropdownbutton>