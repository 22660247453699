import {
  AfterViewInit,
  Component, ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewChild
} from "@angular/core";
import { ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  ComboBoxComponent as KendoComboBoxComponent,
  DropDownFillMode, DropDownFilterSettings,
  DropDownRounded,
  DropDownSize,
  VirtualizationSettings
} from "@progress/kendo-angular-dropdowns";
import { Subject, takeUntil } from "rxjs";
import { BaseAccessor } from "../base/base-accessor";
import { PopupSettings } from "./models";
import {ComboboxTemplateDirective} from "./combobox-template.directive";

@Component({
  selector: "williams-ui-platform-combobox",
  templateUrl: "./combobox.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxComponent),
      multi: true,
    },
  ],
})
export class ComboboxComponent extends BaseAccessor implements AfterViewInit, OnDestroy {
  @ContentChild(forwardRef(() => ComboboxTemplateDirective)) comboboxTemplates!: ComboboxTemplateDirective;
  @ViewChild('combobox', { static: false }) combobox!: KendoComboBoxComponent
  @Input() data: any[] = [];
  @Input() clearButton = true;
  @Input() disabled = false;
  @Input() filterable = false;
  @Input() textField!: string;
  @Input() valueField!: string;
  @Input() valuePrimitive = true;
  @Input() readonly = false;
  @Input() value: any;
  @Input() suggest = true;
  // Hides the arrow down button when set to true
  @Input() hideArrowButton = false;
  @Input() showOptionsOnFocus = true;
  @Input() fillMode: DropDownFillMode = 'solid';
  @Input() rounded: DropDownRounded = 'none';
  @Input() size: DropDownSize = 'medium';
  @Input() showErrors = false ;
  @Input() popupSettings: PopupSettings = {};
  @Input() allowCustom = false;
  @Input() virtual: boolean | VirtualizationSettings = false;
  @Input() customFilter: boolean = false;
  @Input() customFilterSettings: DropDownFilterSettings | string = {};
  
  @Output() filterChange = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<any>();
  @Output() blur = new EventEmitter<void>();

  private destroy$ = new Subject<void>();

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngAfterViewInit(): void {
    if(this.showOptionsOnFocus) {
      this.combobox.onFocus.pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.combobox.togglePopup(true);
      })
    }
  }

  onFilterChange(value: string): void {
    this.filterChange.emit(value);
  }

  onValueChange(value: any): void {
    this.valueChange.emit(value);
  }

  onBlur(): void{
    this.blur.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
