import { CommandColumnActionItem, ExcelOptions, PDFOptions, State } from "../models";
import { CheckboxColumnConfiguration, ColumnConfiguration, CommandColumnConfiguration } from "../models/column";

export const DEFAULT_COLUMN_CONFIGURATION: ColumnConfiguration = {
  autoSize: false,
  cellTemplate: null,
  columnMenu: true,
  cssClass: "",
  editableOn: {
    add: false,
    edit: false
  },
  editor: "text",
  editTemplate: null,
  field: "",
  filter: 'text',
  filterable: true,
  footerClass: "",
  groupable: true,
  headerClass: "",
  hidden: false,
  includeInChooser: true,
  lockable: true,
  locked: false,
  reorderable: true,
  resizable: true,
  sortable: true,
  stickable: false,
  sticky: false,
  title: "",
  width: 150,
  formControlName: "",
  dropdownEditorSettings: {
    data: [],
    textField: 'label',
    valueField: 'value',
    valuePrimitive: false,
    defaultItem: { label: 'Select', value: null }
  },
  numericEditorSettings: {
    decimal: 0,
    format: '#',
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER
  },
  numberFormat: {
    required: false,
    locale: 'en_CA'
  },
  headerTemplate: null,
  type: 'column',
  columnGroupConfig: {
    title: '',
    headerClass: '',
    columnMenu: false
  },
  subColumns: [],
  maxLength: 400,
  textEditorSettings: {
    uppercaseInput: false
  },
  switchEditorSettings: {
    offLabel: "No",
    onLabel: "Yes"
  },
  selectAllRows: false
};

export const DEFAULT_PDF_OPTIONS: PDFOptions = {
  allPages: true,
  author: '',
  autoPrint: false,
  avoidLinks: false,
  creator: '',
  fileName: 'export.pdf',
  landscape: false,
  paperSize: ["1000pt", "800pt"],
  producer: '',
  repeatHeaders: true,
  scale: 1,
  subject: '',
  title: '',
  pdfMargin: {
    left: 4,
    right: 4,
    bottom: 4,
    top: 4
  },
  forcePageBreak: '.something'
}

export const DEFAULT_EXCEL_OPTIONS: ExcelOptions = {
  collapsible: true,
  creator: '',
  fileName: 'export.xlsx',
  filterable: true,
  headerPaddingCellOptions: {},
  paddingCellOptions: {}
}

export const COMMAND_COLUMN_ACTION_ITEMS: CommandColumnActionItem[] = [{
  action: 'copy',
  text: 'Copy',
  disabled: false  
}, {
  action: 'delete',
  text: 'Delete',
  disabled: false
}]


export const DEFAULT_COMMAND_COLUMN_CONFIGURATION: CommandColumnConfiguration = {
  autoSize: false,
  columnMenu: false,
  cssClass: '',
  footerClass: '',
  headerClass: '',
  hidden: false,
  includeInChooser: false,
  lockable: true,
  locked: false,
  reorderable: false,
  resizable: true,
  stickable: true,
  sticky: true,
  title: '',
  width: 50,
  headerTemplate: null,
  selectAllRows: false
}

export const DEFAULT_CHECKBOX_COLUMN_CONFIGURATION: CheckboxColumnConfiguration = {
  autoSize: false,
  columnMenu: false,
  cssClass: '',
  footerClass: '',
  headerClass: '',
  hidden: false,
  includeInChooser: false,
  lockable: true,
  locked: false,
  reorderable: false,
  resizable: true,
  stickable: true,
  sticky: true,
  title: '',
  width: 50,
  showSelectAll: true,
  headerTemplate: null,
  selectAllRows: false
}

export const DEFAULT_GRID_STATE: State = {
  sort: [],
  filter: {
    logic: "and",
    filters: [],
  },
  group: [],
  aggregates: []
};

export const GRID_ROW_NUMBER_FIELD = "grid_row_number";