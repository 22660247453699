<div [ngClass]="{ 'show-errors': showErrors }" [style.width]="containerWidth">
  <kendo-dropdownlist
    class="mt-4 w-color-compTxt-1"
    [data]="data"
    [textField]="textField"
    [valueField]="valueField"
    [value]="value"
    [valuePrimitive]="valuePrimitive"
    [defaultItem]="defaultItem"
    [formControl]="control"
    [itemDisabled]="itemDisabled"
    [size]="size"
    [fillMode]="fillMode"
    [rounded]="rounded"
    [disabled]="disabled"
    [readonly]="readonly"
    (selectionChange)="onSelectionChange($event)"
    [popupSettings]="popupSettings"
    [delay]="delay"
    (open)="onOpen($event)"
    (focus)="onFocus($event)"
  >
  <ng-template  kendoDropDownListValueTemplate let-dataItem>
    <span *ngIf="!dropdownTemplates?.dropdownListValueTemplate" [class.text-ellipsis]="showTextEllipsis">
      {{ isPrimitiveDataSource ? dataItem : dataItem ? dataItem[textField] : '' }}
    </span>
    <ng-container *ngIf="dropdownTemplates?.dropdownListValueTemplate" [ngTemplateOutlet]="dropdownTemplates.dropdownListValueTemplate" [ngTemplateOutletContext]="{ dataItem }"></ng-container>
  <kendo-icon class="dropdownErrorIcon w-icon-color w-mt-thin" *ngIf="showErrorMsg"  name="warning"></kendo-icon>
  </ng-template>
  <ng-template kendoDropDownListItemTemplate let-dataItem *ngIf="dropdownTemplates?.dropdownListItemTemplate">
    <ng-container *ngTemplateOutlet="dropdownTemplates.dropdownListItemTemplate; context: { dataItem }"></ng-container>
  </ng-template>
</kendo-dropdownlist>
</div>