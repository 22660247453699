import { Component, Input } from "@angular/core";
import { IEmbedConfiguration } from "powerbi-client";

@Component({
  selector: "williams-ui-platform-paginated-report",
  templateUrl: "./paginated-report.component.html",
  styleUrls: ["./paginated-report.component.scss"],
})
export class PaginatedReportComponent {
  @Input() reportConfig!: IEmbedConfiguration;
  @Input() containerHeight = "600px";
  constructor() {}
}
