<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<div
  class="extended-grid-wrapper"
  [ngClass]="{ 
    'is-loading': loading,
    'is-readonly': readonly,
    'border-all-footer-cells': showBorderForAllFooterCells,
    'row-count-hidden': !showRowCount,
    'hide-column-footer': (hideColumnFooterOnEmptyData && gridView.total == 0)
  }"
  kendoTooltip
  showOn="hover"
  [tooltipTemplate]="template"
  filter=".k-grid td"
  (mouseover)="showTooltip($event)">
  <kendo-grid
    #grid
    [autoSize]="autoSize"
    [columnMenu]="columnMenu"
    [data]="gridView"
    [filterable]="filterable"
    [groupable]="isGroupable"
    [height]="height"
    [rowHeight]="rowHeight"
    [detailRowHeight]="detailRowHeight"
    [hideHeader]="hideHeader"
    [loading]="loading"
    [navigable]="navigable"
    [pageable]="pageable"
    [pageSize]="pageSize"
    [reorderable]="reorderable"
    [resizable]="resizable"
    [rowClass]="rowClass"
    [scrollable]="scrollable"
    [selectable]="selectable"
    [sortable]="sortable"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [filter]="gridState.filter"
    [group]="gridState.group"
    kendoGridSelectBy="rowId"
    [rowSelected]="rowSelectedFn"
    [(selectedKeys)]="selectedRows"
    (cellClick)="onCellClick($event)"
    (dataStateChange)="onDataStateChange($event)"
    (selectionChange)="onSelectionChange($event)"
    [isDetailExpanded]="isDetailExpandedFn"
    (selectedKeysChange)="onSelectedKeysChange()"
  >
    <kendo-grid-checkbox-column
      [style]="{ 'text-align': 'center' }"
      [headerStyle]="{ 'text-align': 'center' }"
      [autoSize]="checkboxColumnConfiguration.autoSize"
      [columnMenu]="checkboxColumnConfiguration.columnMenu"
      [class]="checkboxColumnConfiguration.cssClass"
      [footerClass]="checkboxColumnConfiguration.footerClass"
      [headerClass]="checkboxColumnConfiguration.headerClass"
      [hidden]="checkboxColumnConfiguration.hidden"
      [includeInChooser]="checkboxColumnConfiguration.includeInChooser"
      [lockable]="checkboxColumnConfiguration.lockable"
      [locked]="checkboxColumnConfiguration.locked"
      [reorderable]="checkboxColumnConfiguration.reorderable"
      [resizable]="checkboxColumnConfiguration.resizable"
      [stickable]="checkboxColumnConfiguration.stickable"
      [sticky]="checkboxColumnConfiguration.sticky"
      [title]="checkboxColumnConfiguration.title"
      [width]="checkboxColumnConfiguration.width"
      [showSelectAll]="checkboxColumnConfiguration.showSelectAll"
    >
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
        *ngIf="checkboxColumnConfiguration.headerTemplate"
      >
        <ng-container
          *ngTemplateOutlet="checkboxColumnConfiguration.headerTemplate; context: { column, columnIndex }"
        >
        </ng-container>
      </ng-template>

        <ng-template kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
        *ngIf="!checkboxColumnConfiguration.headerTemplate && checkboxColumnConfiguration.selectAllRows && scrollable === 'virtual'">
          <input type="checkbox" kendoCheckBox id="selectAllCheckboxId" kendoGridSelectAllCheckbox [state]="selectAllState"
            (selectAllChange)="onSelectAllChange($event)" />
          <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
        </ng-template>
    </kendo-grid-checkbox-column>
    <ng-container *ngFor="let column of columnConfigurations">
      <kendo-grid-column
        *ngIf="column.type === 'column'"
        [autoSize]="column.autoSize"
        [columnMenu]="column.columnMenu"
        [class]="column.cssClass"
        [editable]="true"
        [field]="column.field"
        [filterable]="isColumnFilterable(column)"
        [footerClass]="column.footerClass"
        [groupable]="column.groupable"
        [headerClass]="column.headerClass"
        [hidden]="column.hidden"
        [includeInChooser]="column.includeInChooser"
        [lockable]="column.lockable"
        [locked]="column.locked"
        [reorderable]="column.reorderable"
        [resizable]="column.resizable"
        [sortable]="isColumnSortable(column)"
        [stickable]="column.stickable"
        [sticky]="column.sticky"
        [title]="column.title"
        [width]="column.width"
        [class]="column.cssClass"
        [filter]="column.filter"
        [format]="column.format"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div class="text-ellipsis" show-tooltip *ngIf="column.cellTemplate" (onDoubleClick)="onCellDoubleClick(dataItem,column,rowIndex)">
              <ng-container
                *ngTemplateOutlet="column.cellTemplate; context: { dataItem, rowIndex, field: column.field }"
              >
              </ng-container>
          </div>
          <williams-ui-platform-grid-cell
            *ngIf="!column.cellTemplate"
            [dataItem]="dataItem"
            [columnConfiguration]="column"
            (onDoubleClick)="onCellDoubleClick(dataItem,column,rowIndex)"
        ></williams-ui-platform-grid-cell>
        </ng-template>
  
        <ng-template
          kendoGridEditTemplate
          let-formGroup
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <ng-container *ngIf="!isCellEditable(dataItem, column)">
            <div class="text-ellipsis" show-tooltip *ngIf="column.cellTemplate">
              <ng-container
                *ngTemplateOutlet="column.cellTemplate; context: { dataItem, rowIndex, field: column.field , column: column}"
              >
              </ng-container>
            </div>
            <williams-ui-platform-grid-cell
              *ngIf="!column.cellTemplate"
              [dataItem]="dataItem"
              [columnConfiguration]="column"
            ></williams-ui-platform-grid-cell>
          </ng-container>
          <ng-container *ngIf="isCellEditable(dataItem, column)">
            <ng-container *ngIf="column.editTemplate">
              <ng-container
                *ngTemplateOutlet="column.editTemplate; context: { dataItem, rowIndex, formControlName: column.formControlName, columnConfiguration: column, formGroup }"
              >
              </ng-container>
            </ng-container>
            <williams-ui-platform-grid-cell-edit
              *ngIf="!column.editTemplate"
              [group]="formGroup"
              [columnConfiguration]="column"
            >
            </williams-ui-platform-grid-cell-edit>
          </ng-container>
        </ng-template>
  
        <ng-template kendoGridGroupFooterTemplate let-group="group" let-aggregates="aggregates" let-field="field">
          <div *ngIf="!gridTemplates?.groupFooterTemplate" [class]="column?.footerClass">
            {{ getGroupAggregateForField(aggregates, field) }}
          </div>
          <ng-container *ngIf="gridTemplates?.groupFooterTemplate">
            <ng-container
              *ngTemplateOutlet="gridTemplates.groupFooterTemplate; context: { group, aggregates, field }"
            >
            </ng-container>
          </ng-container>
        </ng-template>
  
        <ng-template
          kendoGridFooterTemplate
          let-columnComponent="column"
          *ngIf="showGridFooterTemplate && (gridTemplates?.footerTemplate || gridState.aggregates.length)"
        >
          <div *ngIf="!gridTemplates?.footerTemplate && gridColumnFooterAggregates[column.field] as aggregateValue;" 
            class="footer-aggregate">
            {{ aggregateValue }}
          </div>
          <ng-container *ngIf="gridTemplates?.footerTemplate">
            <ng-container
              *ngTemplateOutlet="gridTemplates.footerTemplate; context: { column, columnComponent, aggregateValue: gridColumnFooterAggregates[column.field] }"
            >
            </ng-container>
          </ng-container>
        </ng-template>
  
        <ng-template kendoGridFilterCellTemplate let-filter let-columnComponent="column" *ngIf="gridTemplates?.filterCellTemplate">
          <ng-container
            *ngTemplateOutlet="gridTemplates.filterCellTemplate; context: { filter, column, columnComponent }"
          >
          </ng-container>
        </ng-template>
  
        <ng-template kendoGridFilterMenuTemplate let-filter let-columnComponent="column" *ngIf="gridTemplates?.filterMenuTemplate">
          <ng-container
            *ngTemplateOutlet="gridTemplates.filterMenuTemplate; context: { filter, column, columnComponent }"
          >
          </ng-container>
        </ng-template>
        
        <ng-template
          kendoGridHeaderTemplate
          let-columnComponent="column"
          let-columnIndex="columnIndex"
          *ngIf="column.headerTemplate"
        >
          <ng-container
            *ngTemplateOutlet="column.headerTemplate; context: { column, columnComponent, columnIndex }"
          >
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <!-- For Column Group ( Currently supports one level )-->
      <kendo-grid-column-group
        *ngIf="column.type === 'column-group'"
        [title]="column.columnGroupConfig.title"
        [headerClass]="column.columnGroupConfig.headerClass"
        [columnMenu]="column.columnGroupConfig.columnMenu"
        footerClass="w-display-none"
      >
        <ng-container *ngFor="let subColumn of column.subColumns">
          <kendo-grid-column
            [autoSize]="subColumn.autoSize"
            [columnMenu]="subColumn.columnMenu"
            [class]="subColumn.cssClass"
            [editable]="true"
            [field]="subColumn.field"
            [filterable]="isColumnFilterable(subColumn)"
            [footerClass]="subColumn.footerClass"
            [groupable]="subColumn.groupable"
            [headerClass]="subColumn.headerClass"
            [hidden]="subColumn.hidden"
            [includeInChooser]="subColumn.includeInChooser"
            [lockable]="subColumn.lockable"
            [locked]="subColumn.locked"
            [reorderable]="subColumn.reorderable"
            [resizable]="subColumn.resizable"
            [sortable]="isColumnSortable(subColumn)"
            [stickable]="subColumn.stickable"
            [sticky]="subColumn.sticky"
            [title]="subColumn.title"
            [width]="subColumn.width"
            [class]="subColumn.cssClass"
            [filter]="subColumn.filter"
            [format]="subColumn.format"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="text-ellipsis" show-tooltip *ngIf="subColumn.cellTemplate" (onDoubleClick)="onCellDoubleClick(dataItem,column,rowIndex)"
              >
                  <ng-container
                    *ngTemplateOutlet="subColumn.cellTemplate; context: { dataItem, rowIndex, field: subColumn.field }"
                  >
                  </ng-container>
              </div>
              <williams-ui-platform-grid-cell
                *ngIf="!subColumn.cellTemplate"
                [dataItem]="dataItem"
                [columnConfiguration]="subColumn"
                (onDoubleClick)="onCellDoubleClick(dataItem,column,rowIndex)"
            ></williams-ui-platform-grid-cell>
            </ng-template>
      
            <ng-template
              kendoGridEditTemplate
              let-formGroup
              let-dataItem="dataItem"
              let-rowIndex="rowIndex"
            >
              <ng-container *ngIf="!isCellEditable(dataItem, subColumn)">
                <div class="text-ellipsis" show-tooltip *ngIf="subColumn.cellTemplate">
                  <ng-container
                    *ngTemplateOutlet="subColumn.cellTemplate; context: { dataItem, rowIndex, field: subColumn.field }"
                  >
                  </ng-container>
                </div>
                <williams-ui-platform-grid-cell
                  *ngIf="!subColumn.cellTemplate"
                  [dataItem]="dataItem"
                  [columnConfiguration]="subColumn"
                ></williams-ui-platform-grid-cell>
              </ng-container>
              <ng-container *ngIf="isCellEditable(dataItem, subColumn)">
                <ng-container *ngIf="subColumn.editTemplate">
                  <ng-container
                    *ngTemplateOutlet="subColumn.editTemplate; context: { dataItem, rowIndex, formControlName: subColumn.formControlName, columnConfiguration: subColumn, formGroup }"
                  >
                  </ng-container>
                </ng-container>
                <williams-ui-platform-grid-cell-edit
                  *ngIf="!subColumn.editTemplate"
                  [group]="formGroup"
                  [columnConfiguration]="subColumn"
                >
                </williams-ui-platform-grid-cell-edit>
              </ng-container>
            </ng-template>
      
            <ng-template kendoGridGroupFooterTemplate let-group="group" let-aggregates="aggregates" let-field="field">
              <span *ngIf="!gridTemplates?.groupFooterTemplate">
                {{ getGroupAggregateForField(aggregates, field) }}
              </span>
              <ng-container *ngIf="gridTemplates?.groupFooterTemplate">
                <ng-container
                  *ngTemplateOutlet="gridTemplates.groupFooterTemplate; context: { group, aggregates, field }"
                >
                </ng-container>
              </ng-container>
            </ng-template>
      
            <ng-template
              kendoGridFooterTemplate
              let-column
              *ngIf="showGridFooterTemplate && (gridTemplates?.footerTemplate || gridState.aggregates.length)"
            >
              <div *ngIf="!gridTemplates?.footerTemplate && gridColumnFooterAggregates[column.field] as aggregateValue;" 
                class="footer-aggregate">
                {{ aggregateValue }}
              </div>
              <ng-container *ngIf="gridTemplates?.footerTemplate">
                <ng-container
                  *ngTemplateOutlet="gridTemplates.footerTemplate; context: { column, aggregateValue: gridColumnFooterAggregates[column.field] }"
                >
                </ng-container>
              </ng-container>
            </ng-template>
      
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column" *ngIf="gridTemplates?.filterCellTemplate">
              <ng-container
                *ngTemplateOutlet="gridTemplates.filterCellTemplate; context: { filter, column }"
              >
              </ng-container>
            </ng-template>
      
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" *ngIf="gridTemplates?.filterMenuTemplate">
              <ng-container
                *ngTemplateOutlet="gridTemplates.filterMenuTemplate; context: { filter, column }"
              >
              </ng-container>
            </ng-template>

            <ng-template
              kendoGridHeaderTemplate
              let-column
              let-columnIndex="columnIndex"
              *ngIf="subColumn.headerTemplate"
            >
              <ng-container
                *ngTemplateOutlet="subColumn.headerTemplate; context: { column, columnIndex }"
              >
              </ng-container>
            </ng-template>
          </kendo-grid-column>
        </ng-container>
      </kendo-grid-column-group>

      <!-- For Column span (Currently supports one level) -->
      <kendo-grid-span-column *ngIf="column.type === 'span-column'">
        <ng-container *ngFor="let subColumn of column.subColumns">
          <kendo-grid-column
            [autoSize]="subColumn.autoSize"
            [columnMenu]="subColumn.columnMenu"
            [class]="subColumn.cssClass"
            [editable]="true"
            [field]="subColumn.field"
            [filterable]="isColumnFilterable(subColumn)"
            [footerClass]="subColumn.footerClass"
            [groupable]="subColumn.groupable"
            [headerClass]="subColumn.headerClass"
            [hidden]="subColumn.hidden"
            [includeInChooser]="subColumn.includeInChooser"
            [lockable]="subColumn.lockable"
            [locked]="subColumn.locked"
            [reorderable]="subColumn.reorderable"
            [resizable]="subColumn.resizable"
            [sortable]="isColumnSortable(subColumn)"
            [stickable]="subColumn.stickable"
            [sticky]="subColumn.sticky"
            [title]="subColumn.title"
            [width]="subColumn.width"
            [class]="subColumn.cssClass"
            [filter]="subColumn.filter"
            [format]="subColumn.format"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="text-ellipsis" show-tooltip *ngIf="subColumn.cellTemplate" (onDoubleClick)="onCellDoubleClick(dataItem,column,rowIndex)">
                  <ng-container
                    *ngTemplateOutlet="subColumn.cellTemplate; context: { dataItem, rowIndex, field: subColumn.field }"
                  >
                  </ng-container>
              </div>
              <williams-ui-platform-grid-cell
                *ngIf="!subColumn.cellTemplate"
                [dataItem]="dataItem"
                [columnConfiguration]="subColumn"
                (onDoubleClick)="onCellDoubleClick(dataItem,column,rowIndex)"
            ></williams-ui-platform-grid-cell>
            </ng-template>
    
            <ng-template
              kendoGridEditTemplate
              let-formGroup
              let-dataItem="dataItem"
              let-rowIndex="rowIndex"
            >
              <ng-container *ngIf="!isCellEditable(dataItem, subColumn)">
                <div class="text-ellipsis" show-tooltip *ngIf="subColumn.cellTemplate">
                  <ng-container
                    *ngTemplateOutlet="subColumn.cellTemplate; context: { dataItem, rowIndex, field: subColumn.field }"
                  >
                  </ng-container>
                </div>
                <williams-ui-platform-grid-cell
                  *ngIf="!subColumn.cellTemplate"
                  [dataItem]="dataItem"
                  [columnConfiguration]="subColumn"
                ></williams-ui-platform-grid-cell>
              </ng-container>
              <ng-container *ngIf="isCellEditable(dataItem, subColumn)">
                <ng-container *ngIf="subColumn.editTemplate">
                  <ng-container
                    *ngTemplateOutlet="subColumn.editTemplate; context: { dataItem, rowIndex, formControlName: subColumn.formControlName, columnConfiguration: subColumn, formGroup }"
                  >
                  </ng-container>
                </ng-container>
                <williams-ui-platform-grid-cell-edit
                  *ngIf="!subColumn.editTemplate"
                  [group]="formGroup"
                  [columnConfiguration]="subColumn"
                >
                </williams-ui-platform-grid-cell-edit>
              </ng-container>
            </ng-template>
    
            <ng-template kendoGridGroupFooterTemplate let-group="group" let-aggregates="aggregates" let-field="field">
              <span *ngIf="!gridTemplates?.groupFooterTemplate">
                {{ getGroupAggregateForField(aggregates, field) }}
              </span>
              <ng-container *ngIf="gridTemplates?.groupFooterTemplate">
                <ng-container
                  *ngTemplateOutlet="gridTemplates.groupFooterTemplate; context: { group, aggregates, field }"
                >
                </ng-container>
              </ng-container>
            </ng-template>
    
            <ng-template
              kendoGridFooterTemplate
              let-column
              *ngIf="showGridFooterTemplate && (gridTemplates?.footerTemplate || gridState.aggregates.length)"
            >
              <div *ngIf="!gridTemplates?.footerTemplate && gridColumnFooterAggregates[column.field] as aggregateValue;" 
                class="footer-aggregate">
                {{ aggregateValue }}
              </div>
              <ng-container *ngIf="gridTemplates?.footerTemplate">
                <ng-container
                  *ngTemplateOutlet="gridTemplates.footerTemplate; context: { column, aggregateValue: gridColumnFooterAggregates[column.field] }"
                >
                </ng-container>
              </ng-container>
            </ng-template>
    
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column" *ngIf="gridTemplates?.filterCellTemplate">
              <ng-container
                *ngTemplateOutlet="gridTemplates.filterCellTemplate; context: { filter, column }"
              >
              </ng-container>
            </ng-template>
    
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" *ngIf="gridTemplates?.filterMenuTemplate">
              <ng-container
                *ngTemplateOutlet="gridTemplates.filterMenuTemplate; context: { filter, column }"
              >
              </ng-container>
            </ng-template>

            <ng-template
              kendoGridHeaderTemplate
              let-column
              let-columnIndex="columnIndex"
              *ngIf="subColumn.headerTemplate"
            >
              <ng-container
                *ngTemplateOutlet="subColumn.headerTemplate; context: { column, columnIndex }"
              >
              </ng-container>
            </ng-template>
        </kendo-grid-column>
        </ng-container>
      </kendo-grid-span-column>

    </ng-container>
    <kendo-grid-command-column
      [autoSize]="commandColumnConfiguration.autoSize"
      [columnMenu]="commandColumnConfiguration.columnMenu"
      [class]="commandColumnConfiguration.cssClass"
      [footerClass]="commandColumnConfiguration.footerClass"
      [headerClass]="commandColumnConfiguration.headerClass"
      [hidden]="commandColumnConfiguration.hidden"
      [includeInChooser]="commandColumnConfiguration.includeInChooser"
      [lockable]="commandColumnConfiguration.lockable"
      [locked]="commandColumnConfiguration.locked"
      [reorderable]="commandColumnConfiguration.reorderable"
      [resizable]="commandColumnConfiguration.resizable"
      [stickable]="commandColumnConfiguration.stickable"
      [sticky]="commandColumnConfiguration.sticky"
      [title]="commandColumnConfiguration.title"
      [width]="commandColumnConfiguration.width"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <ng-container *ngIf="gridTemplates?.commandColumnTemplate">
          <ng-container
            *ngTemplateOutlet="gridTemplates.commandColumnTemplate; context: { dataItem, rowIndex }"
          >
          </ng-container>
        </ng-container>
        <williams-ui-platform-dropdown-button
          *ngIf="!gridTemplates?.commandColumnTemplate"
          icon="more-vertical"
          [data]="getCommandColumnActionItems(dataItem)"
          [popupSettings]="{ align: 'right' }"
          fillMode="outline"
          [disabled]="!allowGridEditing"
          size="small"
          themeColor="base"
          (itemClick)="handleCommandActionClick($event, dataItem)"
        >
        </williams-ui-platform-dropdown-button>
      </ng-template>

      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <ng-container
          *ngTemplateOutlet="commandColumnConfiguration.headerTemplate ?? defaultCommandColumnHeaderTemplate; context: { column, columnIndex }"
        >
        </ng-container>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-pdf
      [allPages]="pdfOptions.allPages"
      [author]="pdfOptions.author"
      [autoPrint]="pdfOptions.autoPrint"
      [avoidLinks]="pdfOptions.avoidLinks"
      [creator]="pdfOptions.creator"
      [fileName]="pdfOptions.fileName"
      [forcePageBreak]="pdfOptions.forcePageBreak"
      [landscape]="pdfOptions.landscape"
      [paperSize]="pdfOptions.paperSize"
      [producer]="pdfOptions.producer"
      [repeatHeaders]="pdfOptions.repeatHeaders"
      [scale]="pdfOptions.scale"
      [subject]="pdfOptions.subject"
      [title]="pdfOptions.title"
      [margin]="pdfOptions.pdfMargin"
    >
      <ng-template
        kendoGridPDFTemplate
        let-pageNum="pageNum"
        let-totalPages="totalPages"
        *ngIf="gridTemplates?.pdfTemplate"
      >
        <ng-container
          *ngTemplateOutlet="gridTemplates.pdfTemplate; context: { pageNum, totalPages }"
        >
        </ng-container>
      </ng-template>
    </kendo-grid-pdf>
  
    <kendo-grid-excel
      [collapsible]="excelOptions.collapsible"
      [creator]="excelOptions.creator"
      [fileName]="excelOptions.fileName"
      [filterable]="excelOptions.filterable"
      [headerPaddingCellOptions]="excelOptions.headerPaddingCellOptions"
      [paddingCellOptions]="excelOptions.paddingCellOptions"
      [fetchData]="allDataForExcelExport"
    >
    </kendo-grid-excel>

    <ng-template kendoGridLoadingTemplate>
      <div class="grid-loader-container">
        <williams-ui-platform-loader></williams-ui-platform-loader>
      </div>
    </ng-template>
    <kendo-grid-messages filterClearButton="Cancel" filterFilterButton="Set"></kendo-grid-messages>
    <ng-template kendoGridNoRecordsTemplate *ngIf="gridTemplates?.noRecordsTemplate">
      <ng-container
        *ngTemplateOutlet="gridTemplates.noRecordsTemplate"
      >
      </ng-container>
    </ng-template>
    <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex" *ngIf="gridTemplates?.detailTemplate" [kendoGridDetailTemplateShowIf]="detailTemplateShowIfFn">
      <ng-container
        *ngTemplateOutlet="gridTemplates.detailTemplate; context: { dataItem, rowIndex }"
      >
      </ng-container>
   </ng-template>
  </kendo-grid>

  <div class="k-grid-footer-wrap" *ngIf="gridTemplates?.footerDetailTemplate">
    <ng-container
      *ngTemplateOutlet="gridTemplates.footerDetailTemplate">
    </ng-container>
  </div>
  
  <div class="grid-footer w-px-2 w-py-1" *ngIf="showRowCount">
    <span class="w-base-text-bold">
      {{ rowCount + ' ' + rowCountLabel + (rowCount > 1 ? 's' : '') }}
    </span>
  </div>
</div>


<ng-template #defaultCommandColumnHeaderTemplate>
  <div [style.width]="'24px'"></div>
</ng-template>