import { Component, EventEmitter, forwardRef, Input, Optional, Output } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckBoxRounded, InputSize } from '@progress/kendo-angular-inputs';
import { BaseAccessor } from '../base/base-accessor';


@Component({
  selector: 'williams-ui-platform-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent extends BaseAccessor {
  @Input() size: InputSize = 'medium';
  @Input() rounded: CheckBoxRounded = 'medium';
  @Input() checked! : boolean;
  @Input() isDisabled : boolean =false;

  @Output()
  checkboxChange = new EventEmitter<any>();
  @Input() elementId!:string;
  
  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }
  public onClick() {
    this.checked = !this.checked;
    this.checkboxChange.emit(this.checked);
  }

}
