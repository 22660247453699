import {Component, Input} from '@angular/core';
import {ErrorComponent} from "@progress/kendo-angular-inputs";

@Component({
  selector: 'williams-ui-platform-formerror',
  templateUrl: './form-error.component.html'
})
export class FormErrorComponent extends ErrorComponent  {
  @Input() showErrors: boolean = false;
}
