import {
    Component, EventEmitter, Input, OnInit,
    Output
} from "@angular/core";
import { Observable, Subject } from "rxjs";
@Component({
    selector: 'bookmark-component',
    templateUrl: './bookmark.component.html',
    styles: ['.bookmark-icon { background: transparent; box-shadow: none; border: none; vertical-align: middle;cursor: pointer; }']
})
export class BookmarkComponent implements OnInit {
    @Input() isFavouriteAdded$!: Observable<boolean>;
    @Output() updateBookmark: EventEmitter<any> = new EventEmitter<any>();
    
    constructor() {}

    ngOnInit() {}

    addOrRemoveFavourite(isBookMarked: boolean) {
        this.isFavouriteAdded$ = new Observable<boolean>((observer) => {
            observer.next(isBookMarked);
        })
        this.updateBookmark.emit();
    }

}