import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[wDateOverride]'
})
export class DateOverrideBadgeDirective implements AfterViewInit {

    @Input('badgeContent') badgeContent = 'Date Override Mode';

    @Input('badgeStyles') badgeStyles: any = {
        color: '#0B1E25',
        backgroundColor: '#EEC51A',
        fontSize: '10px',
        borderRadius: "25px",
        width: '140px',
        padding: '8px',
        lineHeight: '12px',
        letterSpacing: '0.5px',
        textTransform: 'Uppercase',
        display: 'inline-block',
        textAlign: 'center',
        fontWeight: 'bold',
        verticalAlign: 'middle'
    }

    constructor(
        private elementRef: ElementRef
    ) { }

    ngAfterViewInit() {
        this.addBadge();
    }

    private addBadge() {
        let element = this.elementRef.nativeElement;
        element.innerText = this.badgeContent;
        Object.keys(this.badgeStyles).forEach(style => {
            element.style[style] = this.badgeStyles[style];
        });
    }

}