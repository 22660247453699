import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  InputFillMode,
  InputRounded,
  InputSize,
} from "@progress/kendo-angular-inputs";
import { BaseAccessor } from "../base/base-accessor";

@Component({
  selector: "williams-ui-platform-numeric-text",
  templateUrl: "./numeric-text.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericTextComponent),
      multi: true,
    },
  ],
})
export class NumericTextComponent extends BaseAccessor implements AfterViewInit, OnDestroy, OnInit {
  @Input() decimals: number = 0;
  @Input() format: string = "";
  @Input() value!: number;
  @Input() fillMode: InputFillMode = "solid";
  @Input() rounded: InputRounded = "none";
  @Input() size: InputSize = "medium";
  @Input() min!: number;
  @Input() max!: number;
  @Input() autoCorrect: boolean = true;
  @Input() changeValueOnScroll: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder!: string;
  @Input() rangeValidation: boolean = false;
  @Input() readonly: boolean = false;
  @Input() selectOnFocus: boolean = false;
  @Input() step!: number;
  @Input() tabindex!: number;
  @Input() title!: string;
  @Input() maxLength: number = 400;
  @Input() spinners = false;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  private _inputEl!: HTMLInputElement;
  private _prevValue!: any;
  private _prevValueChangeValue!: any;
  private _prevAutoCorrectedValue!: any;
  private _hasValuechangedAfterFocus = false;

  constructor(@Optional() public override controlContainer: ControlContainer, private _elRef: ElementRef) {
    super(controlContainer);
    this._handleKeyDownEvent = this._handleKeyDownEvent.bind(this);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this._inputEl = this._elRef.nativeElement.querySelector("input");
    if(this._inputEl) {
      this._inputEl.addEventListener("keydown", this._handleKeyDownEvent)
    }
  }

  onBlur(event: any) {
    this._prevValueChangeValue = null;
    if(this._hasValuechangedAfterFocus && (this._prevAutoCorrectedValue != this.control.value)) {
      this.control.setValue(this._prevAutoCorrectedValue);
    }
    this._prevAutoCorrectedValue = null;
    this._hasValuechangedAfterFocus = false;
    this.blur.emit(event);
  }
  onFocus(event: any) {
    this.focus.emit(event);
  }

  private _handleKeyDownEvent(event: KeyboardEvent): void {
    const control = this.control as FormControl;
    // Calculating maximum digits allowed based on max and min inputs.
    // As across we have used max and min to determine number of digits to be allowed rather than for checking actual min/max value. Validator.min and Validator.max validations should also be handled separately in component level using this input.
    const positiveMaxInteger = `${Math.abs(Math.trunc(this.max))}`.length;
    const negativeMaxInteger = `${Math.abs(Math.trunc(this.min))}`.length;

    // Preventing negative sign if min allowed value is greater than or equal to 0
    if(this.min >= 0 && event.code === "Minus") {
      event.preventDefault();
    }

    // If decimals is set to 0 then preventing user from entering more than max number of digits allowed
    if (this.decimals === 0 && event.code.includes("Digit")) {
      if (
        !isNaN(negativeMaxInteger) &&
        control.value < 0 &&
        `${Math.abs(control.value)}`.length === negativeMaxInteger
      ) {
        event.preventDefault();
      }

      if (
        !isNaN(positiveMaxInteger) &&
        control.value > 0 &&
        `${Math.abs(control.value)}`.length === positiveMaxInteger
      ) {
        event.preventDefault();
      }
    }

    if(this._prevValueChangeValue != null && event.code.includes("Digit")) {
      const maxInteger = this._prevValueChangeValue < 0 ? negativeMaxInteger : positiveMaxInteger;
      if(this.decimals > 0 && this._prevValueChangeValue && (!`${this._prevValueChangeValue}`.includes(".")) && (`${this._prevValueChangeValue}`.length == (maxInteger + this.decimals))) {
        event.preventDefault();
      }
    }
  }

  private _getAutoCorrectValue(value: number): number | string {
    if (
      !this.autoCorrect ||
      value == null ||
      this.max == null ||
      this.min == null
    ) {
      return value;
    }

    // Calculating maximum digits allowed based on max and min inputs.
    // As across we have used max and min to determine number of digits to be allowed rather than for checking actual min/max value. Validator.min and Validator.max validations should also be handled separately in component level using this input.
    const positiveMaxInteger = `${Math.abs(Math.trunc(this.max))}`.length;
    const negativeMaxInteger = `${Math.abs(Math.trunc(this.min))}`.length;
    const isValueNegative = value < 0;
    // Not manipulating user input if min, max has not been set
    if (isValueNegative && isNaN(negativeMaxInteger)) {
      return value;
    }
    if (!isValueNegative && isNaN(positiveMaxInteger)) {
      return value;
    }

    const valueHasDecimalPoint = `${value}`.includes(".");
    if (valueHasDecimalPoint) {
      const maxIntegerLength = isValueNegative
        ? negativeMaxInteger
        : positiveMaxInteger;
      const absoluteValue = Math.abs(value);

      const [valueIntegerPart, valueDecimalPart] = `${absoluteValue}`.split(
        "."
      );
      if (maxIntegerLength < valueIntegerPart.length) {
        const cursorPosition = this._inputEl.selectionStart;
        const newIntegerPart = valueIntegerPart.slice(0, maxIntegerLength);
        const newDecimalPart = `${valueIntegerPart.slice(
          maxIntegerLength
        )}${valueDecimalPart}`.slice(0, this.decimals);
        setTimeout(() => {
          const newCursorPosition =
            cursorPosition! < maxIntegerLength
              ? cursorPosition
              : cursorPosition! + 1;
          this._inputEl.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
        return `${
          isValueNegative ? "-" : ""
        }${newIntegerPart}.${newDecimalPart}`;
      } else {
        return value;
      }
    }

    // Adding decimal point(.) programmatically if user types more than the number of digits allowed before decimal point
    const valueLength = `${value}`.length;
    const maxIntegerLength = isValueNegative
      ? negativeMaxInteger
      : positiveMaxInteger;
    const absoluteValue = Math.abs(value);
    if (valueLength > maxIntegerLength) {
      const integerPart = `${absoluteValue}`.slice(0, maxIntegerLength);
      const decimalPart = `${absoluteValue}`.slice(maxIntegerLength);
      return `${isValueNegative ? "-" : ""}${integerPart}.${decimalPart}`;
    }

    return value;
  }

  onValueChange(value: number) {
    this._hasValuechangedAfterFocus = true;
    this._prevValueChangeValue = value;
    if(value === null) {
      this._prevValue = null;
      this._prevAutoCorrectedValue = null;
      return;
    }
    let autoCorrectedValue = this._getAutoCorrectValue(value);
    if (autoCorrectedValue != null) {
      autoCorrectedValue = Number(autoCorrectedValue);
    }
    this._prevAutoCorrectedValue = autoCorrectedValue;
    if((autoCorrectedValue == 0 && (this._prevValue == null || this._prevValue == 0)) || this._prevValue == autoCorrectedValue) {
      return;
    }
    this._prevValue = autoCorrectedValue;
    this.control.setValue(autoCorrectedValue);
    this._prevValueChangeValue = null;
    this.valueChange.emit(autoCorrectedValue);
  }

  ngOnDestroy(): void {
    if (this._inputEl) {
      this._inputEl.removeEventListener("keydown", this._handleKeyDownEvent);
    }
  }
}
