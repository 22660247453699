<ng-container *ngIf="!elementId; else focusedElement">
  <kendo-multicolumncombobox
  [allowCustom]="allowCustom"
  [clearButton]="clearButton"
  [data]="data"
  [disabled]="disabled"
  [fillMode]="fillMode"
  [filterable]="filterable"
  [formControl]="control"
  [itemDisabled]="itemDisabledCallback"
  [listHeight]="listHeight"
  [loading]="loading"
  [placeholder]="placeholder"
  [popupSettings]="popupSettings"
  [readonly]="readonly"
  [rounded]="rounded"
  [size]="size"
  [suggest]="suggest"
  [textField]="textField"
  [value]="value"
  [valueField]="valueField"
  [valueNormalizer]="valueNormalizerCallback"
  [valuePrimitive]="valuePrimitive"
  [virtual]="virtual"
  (selectionChange)="onSelectionChange($event)"
  (opened)="onOpened($event)"
  (closed)="onClosed($event)"
  (filterChange)="onFilterChange($event)"
  (valueChange)="onValueChange($event)"
  [style.width]="width"
>
  <kendo-combobox-column
    *ngFor="let column of columnDefinitions"
    [field]="column.field"
    [title]="column.title"
    [width]="column.width"
    [class]="column.class ?? ''"
  >
    <ng-template
      kendoMultiColumnComboBoxColumnCellTemplate
      let-dataItem
      let-column="column"
      *ngIf="multiColumnComboBoxTemplates?.columnCellTemplate"
    >
      <ng-container
        *ngTemplateOutlet="
        multiColumnComboBoxTemplates.columnCellTemplate;
          context: { $implicit: dataItem, dataItem, column }
        "
      ></ng-container>
    </ng-template>
    <ng-template
      kendoMultiColumnComboBoxColumnHeaderTemplate
      let-column
      *ngIf="multiColumnComboBoxTemplates?.columnHeaderTemplate"
    >
      <ng-container
        *ngTemplateOutlet="
        multiColumnComboBoxTemplates.columnHeaderTemplate;
          context: { $implicit: column, column }
        "
      ></ng-container>
    </ng-template>
  </kendo-combobox-column>

  <ng-template
    kendoMultiColumnComboBoxHeaderTemplate
    *ngIf="multiColumnComboBoxTemplates?.headerTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.headerTemplate
      "
    ></ng-container>
  </ng-template>
  <ng-template
    kendoMultiColumnComboBoxFooterTemplate
    *ngIf="multiColumnComboBoxTemplates?.footerTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.footerTemplate
      "
    ></ng-container>
  </ng-template>
  <ng-template
    kendoMultiColumnComboBoxNoDataTemplate
    *ngIf="multiColumnComboBoxTemplates?.noDataTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.noDataTemplate
      "
    ></ng-container>
  </ng-template>
  <ng-template
    kendoMultiColumnComboBoxGroupTemplate
    let-groupName
    *ngIf="multiColumnComboBoxTemplates?.groupTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.groupTemplate;
        context: { $implicit: groupName, groupName }
      "
    ></ng-container>
  </ng-template>
  <ng-template
    kendoMultiColumnComboBoxFixedGroupTemplate
    let-groupName
    *ngIf="multiColumnComboBoxTemplates?.fixedGroupTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.fixedGroupTemplate;
        context: { $implicit: groupName, groupName }
      "
    ></ng-container>
  </ng-template>
</kendo-multicolumncombobox>
</ng-container>
<ng-template #focusedElement>
  <kendo-multicolumncombobox
  [allowCustom]="allowCustom"
  [clearButton]="clearButton"
  [data]="data"
  [disabled]="disabled"
  [fillMode]="fillMode"
  [filterable]="filterable"
  [formControl]="control"
  [itemDisabled]="itemDisabledCallback"
  [listHeight]="listHeight"
  [loading]="loading"
  [placeholder]="placeholder"
  [popupSettings]="popupSettings"
  [readonly]="readonly"
  [rounded]="rounded"
  [class.ng-invalid]="showError"
  [size]="size"
  [suggest]="suggest"
  [textField]="textField"
  [value]="value"
  [valueField]="valueField"
  [valueNormalizer]="valueNormalizerCallback"
  [valuePrimitive]="valuePrimitive"
  [virtual]="virtual"
  [id]="elementId"
  (selectionChange)="onSelectionChange($event)"
  (opened)="onOpened($event)"
  (closed)="onClosed($event)"
  (filterChange)="onFilterChange($event)"
  (valueChange)="onValueChange($event)"
>
  <kendo-combobox-column
    *ngFor="let column of columnDefinitions"
    [field]="column.field"
    [title]="column.title"
    [width]="column.width"
    [class]="column.class ?? ''"
  >
    <ng-template
      kendoMultiColumnComboBoxColumnCellTemplate
      let-dataItem
      let-column="column"
      *ngIf="multiColumnComboBoxTemplates?.columnCellTemplate"
    >
      <ng-container
        *ngTemplateOutlet="
        multiColumnComboBoxTemplates.columnCellTemplate;
          context: { $implicit: dataItem, dataItem, column }
        "
      ></ng-container>
    </ng-template>
    <ng-template
      kendoMultiColumnComboBoxColumnHeaderTemplate
      let-column
      *ngIf="multiColumnComboBoxTemplates?.columnHeaderTemplate"
    >
      <ng-container
        *ngTemplateOutlet="
        multiColumnComboBoxTemplates.columnHeaderTemplate;
          context: { $implicit: column, column }
        "
      ></ng-container>
    </ng-template>
  </kendo-combobox-column>

  <ng-template
    kendoMultiColumnComboBoxHeaderTemplate
    *ngIf="multiColumnComboBoxTemplates?.headerTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.headerTemplate
      "
    ></ng-container>
  </ng-template>
  <ng-template
    kendoMultiColumnComboBoxFooterTemplate
    *ngIf="multiColumnComboBoxTemplates?.footerTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.footerTemplate
      "
    ></ng-container>
  </ng-template>
  <ng-template
    kendoMultiColumnComboBoxNoDataTemplate
    *ngIf="multiColumnComboBoxTemplates?.noDataTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.noDataTemplate
      "
    ></ng-container>
  </ng-template>
  <ng-template
    kendoMultiColumnComboBoxGroupTemplate
    let-groupName
    *ngIf="multiColumnComboBoxTemplates?.groupTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.groupTemplate;
        context: { $implicit: groupName, groupName }
      "
    ></ng-container>
  </ng-template>
  <ng-template
    kendoMultiColumnComboBoxFixedGroupTemplate
    let-groupName
    *ngIf="multiColumnComboBoxTemplates?.fixedGroupTemplate"
  >
    <ng-container
      *ngTemplateOutlet="
      multiColumnComboBoxTemplates.fixedGroupTemplate;
        context: { $implicit: groupName, groupName }
      "
    ></ng-container>
  </ng-template>
</kendo-multicolumncombobox>
</ng-template>

