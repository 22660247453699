import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInfo, FileRestrictions, FileSelectComponent, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { BaseAccessor } from '../base/base-accessor';

@Component({
  selector: "williams-ui-platform-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent extends BaseAccessor {
  @Input() dropCustomText!: string;
  @Input() disabled: boolean = false;
  @Input() fileTypeCustomText!: string;
  @Input() select: string = "Choose File";
  @Output() fileSelect = new EventEmitter<any>();
  @Output() fileRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('kendoFileSelectInstance') private fileselect!: any;
  

  @Input() fileRestrictions: FileRestrictions = {
    allowedExtensions: [".csv"],
  };

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }
  ngAfterViewInit() {
    this.insertIcon();
  }

  public insertIcon() {
      let selectFile = this.fileselect?.fileSelectButton?.nativeElement;
      let icon = document.createElement("span");
      icon.setAttribute("class", "k-icon k-i-upload");
      selectFile?.insertBefore(icon, selectFile?.childNodes[0]);
  }
  removeEventHandler(event: RemoveEvent) {
    this.fileRemove.emit(event);
  }

  valueChange(event: File[]) {
    this.fileChange.emit(event);
  }
  onBlur(event: any) {
    this.blur.emit(event);
  }
  onFocus(event: any) {
    this.focus.emit(event);
  }

  onSelect(event: SelectEvent) {
    this.selectEvent.emit(event);
  }
}
