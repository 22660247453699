import { Component, ContentChild, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { BreadCrumbComponent as KendoBreadCrumbComponent} from '@progress/kendo-angular-navigation';
import {filter, Subject, Subscription, takeUntil} from 'rxjs';
import { BreadCrumbTemplateDirective } from './directive/bread-crumb-template.directive';
import { BreadCrumbCollapseMode, BreadCrumbItem } from './model';
import { BreadCrumbSVGIcon } from './model/bread-crumb';

@Component({
  selector: 'williams-ui-platform-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit, OnDestroy {

@ViewChild('kendoBreadCrumbInstance') kendoBreadCrumbInstance!: KendoBreadCrumbComponent;
@ContentChild(
  forwardRef(() => BreadCrumbTemplateDirective)
)
private subscription: Subscription | undefined;
private destroy$ = new Subject<boolean>();
breadCrumbTemplateDirective!: any;
/**
 * Specifies the collapse mode of the Breadcrumb .
 * The possible values are:
 * auto (default)—items are automatically collapsed based on the width of the Breadcrumb.
 * wrap—items are wrapped on multiple rows.
 * none—all items are expanded on the same row.
 * For more information and example refer to the Collapse Modes article.
 */
@Input() collapseMode: BreadCrumbCollapseMode= 'auto';
/**
 * The collection of items that will be rendered in the Breadcrumb.
 */
 @Input() items: BreadCrumbItem[] = [
];
/**
 * Defines a name of a built-in icon in a Kendo UI theme.
 */
@Input() separatorIcon!: string;
/**
 * Defines an SVGIcon to be rendered as a separator.
 */
@Input() SVGIcon!: BreadCrumbSVGIcon;

/**
 * If itemClickHandler defined on the parent side
 */
@Input() itemClickHandler!: (item: BreadCrumbItem) =>{};

@Input() pathDisplayMap : any = {
};

@Input() show = false;

@Output() itemClick : EventEmitter<BreadCrumbItem>  = new EventEmitter<BreadCrumbItem>();



constructor(private router: Router) {

}

ngOnInit(): void {
  this.initRoutes();
  this.subscription = new Subscription();
}

  private initRoutes(): void {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
    ).subscribe(() => {
      this.updateBreadcrumb();
    });

    // Initialize breadcrumb on first load
    this.updateBreadcrumb();
  }

  private updateBreadcrumb(): void {
    const route = this.router.url;
    const firstItem = (this.items.length > 0) ? { ...this.items[0] } : {};
    this.items = route
        .substring(0, route.indexOf("?") !== -1 ? route.indexOf("?") : route.length)
        .split("/")
        .filter(Boolean)
        .map((segment) => {
          const breadCrumbText = (this.pathDisplayMap && this.pathDisplayMap[segment]) ? this.pathDisplayMap[segment] : segment;
          return {
            text: breadCrumbText,
            title: segment,
          };
        });

    this.items = [firstItem, ...this.items];
  }

private itemClicked(item: BreadCrumbItem): void{
  const selectedItemIndex = this.items.findIndex((i) => i.text === item.text);
  const url = this.items
    .slice(0, selectedItemIndex + 1)
    .map((i: BreadCrumbItem ) => (i.title)?i.title.toLowerCase():'');
  this.router.navigate(url);
  this.itemClick.emit(item);
}

public onItemClick(item: BreadCrumbItem): void {
    if(!this.itemClickHandler){
      this.itemClicked(item);
    }else {
      this.itemClickHandler(item);
    }
    this.itemClick.emit(item);
}

ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.complete();
  if(this.subscription){
    this.subscription.unsubscribe();
  }
}
}
