import {TreeViewComponent} from "@williams-ui-platform/w-ui-angular";
import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'williams-ui-platform-indeterminate-check-tree-view',
    templateUrl: './indeterminate-check-tree-view.component.html',
})
export class IndeterminateCheckTreeViewComponent extends TreeViewComponent {
    @Input() checkedKeys: string[] = [];
    @Input() customCheck: boolean = false;
    @Output() checkedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    constructor() {
        super();
    }

    onCheckedKeysChange(event: string[]) {
        this.checkedKeysChange.emit(event);
    }
}