<div
  [formGroup]="group"
  [class]="columnClassCallback(dataItem, columnDef.field)"
>
  <williams-ui-platform-text-input
    *ngIf="columnDef.editType == 'text'"
    type="text"
    [formGroup]="group"
    [formControlName]="columnDef.formControlName ?? columnDef.field"
    [clearButton]="true"
    showErrorIcon="initial"
    size="small"
  ></williams-ui-platform-text-input>
  <williams-ui-platform-dropdown
    *ngIf="columnDef.editType == 'dropdown'"
    [data]="columnDef.dropdownOptions ?? []"
    [textField]="columnDef.dropdownSettings?.textField ?? 'label'"
    [valueField]="columnDef.dropdownSettings?.valueField ?? 'value'"
    [valuePrimitive]="columnDef.dropdownSettings?.valuePrimitive ?? true"
    [defaultItem]="{ label: 'Select', value: null }"
    [formControlName]="columnDef.formControlName ?? columnDef.field"
    [formGroup]="group"
    size="small"
  ></williams-ui-platform-dropdown>
</div>
