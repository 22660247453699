import { Component, forwardRef, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputRounded, TextAreaResize } from '@progress/kendo-angular-inputs';
import { BaseAccessor } from '../base/base-accessor';

@Component({
  selector: 'williams-ui-platform-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true
    }
  ]
})
export class TextAreaComponent extends BaseAccessor {
  @Input() resizable!:TextAreaResize;
  @Input() maxlength!:number;
  @Input() showCharacterCount :boolean = false;
  @Input() rows:number = 3;
  @Input() showErrorMsg :boolean = false;
  @Input() rounded: InputRounded = 'none';
  @Input() errorLabel!:string;
  charachtersCount: number =0;
  counter!: string;

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.counter = `${this.charachtersCount}/${this.maxlength}`;
  }
  
  resetCounter() {
    this.charachtersCount = 0;
    this.counter = `${this.charachtersCount}/${this.maxlength}`;
  }

  public onValueChange(ev: string): void {
    this.charachtersCount = ev.length;
    this.counter = `${this.charachtersCount}/${this.maxlength}`;
  }

}
