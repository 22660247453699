import { Component, ContentChild, ContentChildren, forwardRef, OnInit, QueryList } from '@angular/core';
import { CategoryAxisItemComponent } from '../category-axis-item/category-axis-item.component';
import { ValueAxisItemComponent } from '../value-axis-item/value-axis-item.component';

@Component({
  selector: 'williams-ui-platform-category-axis',
  templateUrl: './category-axis.component.html',
  styleUrls: ['./category-axis.component.scss']
})
export class CategoryAxisComponent implements OnInit {
  
  @ContentChildren(CategoryAxisItemComponent)
  axisItems!: QueryList<CategoryAxisItemComponent>;

  
  
  constructor() { }

  ngOnInit(): void {
  }

}
