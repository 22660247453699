/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 * 
 * @param text - The text to be rendered.
 * @param font - The css font descriptor that text is to be rendered with (e.g. "400 14px Helvetica").
 * @returns 
 */
export const calculateTextWidth = (text: string, font: string): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.font = font;
  const metrics = context.measureText(text);
  return Math.ceil(metrics.width);
}