import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'williams-ui-platform-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],

})
export class HeaderComponent implements OnInit {
  pathDisplayMap : any = {
    "capacity": "Capacity",
    "capacity-report":"Discovery Pipeline Operationally Available Capacity",
    "operationally-available":"Operationally Available",
    "cap_alloc_log":"Capacity Allocation Log",
    "unsubscribed":"Unsubscribed",
    "indexofcustomers":"Index of Customers",
    "locations":"Locations",
    "standard-of-conduct":"Standard of Conduct",
    "nme_addr":"Affiliate Names & Addresses",
    "rf_disc_actn":"Affiliate Tariff Waivers",
    "comp_ofcr":"Chief Compliance Officer",
    "posted":"Posted",
    "emp_xfers":"Employee Transfers",
    "impl_proc":"Implementation Procedures",
    "info_disc":"Information Disclosure",
    "potn_mrgrs":"Potential Mergers",
    "shrd_fclt":"Shared Facilities",
    "vol_const_disc":"Voluntary Consent",
    "postedImbalances":"Posted Imbalances",
    "transactionalReporting":"Transactional Reporting",
    "trans_firm":"Firm",
    "job_desc":"Job Titles & Description",
    "trans_inter":"Interruptible",
    "search":"Search",
    "tariff":"Tariff",
    "notices":"Notices",
    "recovery":"Waste Heat Recovery",
    "waiver":"NAESB Waiver",
    "gas-quality":"Gas Quality",
    "sampling":"Gas Sampling Procedures",
    "flowing-gas":"Flowing Gas",
    "critical-notices":"Critical Notices",
    "non-critical-notices":"Non Critical Notices",
    "planned-service-outages":"Planned Service Outages Notices",
    "request-to-purchase":"Request To Purchase Releasable Capacity Notices",
    "critical-notice-detail":"Critical Notice Detail",
    "request-to-purchase-detail":"Request To Purchase Releasable Capacity Notice Detail",
    "non-critical-notice-detail":"Non Critical Notice Detail",
    "planned-service-outages-detail":"Planned Service Outages Notice Detail",
    "site-map":"Site Map",
    "contacts":"Contacts",
    "downloads":"Downloads",
    "trf_disc_actn":"Affiliate Tariff Waivers",
    "error": "Error",
    "capacityhtml": "Capacity",
    "gas-qualityhtml": "Gas Quality",
    "noticeshtml": "Notices",
  }
  date = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
  breadCrumb = [{ text: "", title: "", icon: "home" }];
  showBreadCrumb = false;

  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      if (event.urlAfterRedirects !== '/home') {
        this.showBreadCrumb = true;
      } else {
        this.showBreadCrumb = false;
      }
    });
  }
  ngOnInit(): void {
  }

}
