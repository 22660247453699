import { Component, ContentChild, Input } from '@angular/core';
import { ListViewFooterDirective } from '../../../directives/list-view-footer.directive';
import { ListViewHeaderDirective } from '../../../directives/list-view-header.directive';
import { ListViewItemDirective } from '../../../directives/list-view-item.directive';

/**
 * Usage example
 * 
 * <williams-ui-platform-list-view [data]="data">
 *    <williams-ui-platform-list-view-header>
 *        <ng-template #headerTemplate>
 *            Header
 *        </ng-template>
 *    </williams-ui-platform-list-view-header>
 *    <williams-ui-platform-list-view-item>
 *        <ng-template #itemTemplate let-dataItem="dataItem" let-index="index" let-isLast="isLast" let-isFirst="isFirst">
 *            {{ dataItem }} {{ index }} {{ isLast }} {{ isFirst }}
 *        </ng-template>
 *    </williams-ui-platform-list-view-item>
 *    <williams-ui-platform-list-view-footer>
 *        <ng-template #footerTemplate>
 *            Footer
 *        </ng-template>
 *    </williams-ui-platform-list-view-footer>
 * </williams-ui-platform-list-view>
 * 
 */


@Component({
  selector: 'williams-ui-platform-list-view',
  templateUrl: './list-view.component.html'
})
export class ListViewComponent {
  /**
   * Css class will be applied to kendo listview component root.
   */
  @Input() cssClass: string = "";
  /**
   * Data collection for list view
   */
  @Input() data: any[] = [];
  /**
   * Height of list view component in pixels
   */
  @Input() height!: number;
  /**
   * The CSS class that will be rendered on each item element wrapper of the ListView.
   * Supports the type of values that are supported by [ngClass]
   */
  @Input() itemClass: string | string[] | Set<string> | { [key: string]: boolean } = '';
  /**
   * The CSS styles that will be rendered on each item element wrapper of the ListView.
   * Supports the type of values that are supported by [ngStyle]
   */
  @Input() itemStyle!: { [key: string]: string };
  /**
   * The CSS class that will be rendered on the content container element of the ListView.
   * Supports the type of values that are supported by [ngClass]
   */
  @Input() containerClass: string | string[] | Set<string> | { [key: string]: boolean } = '';
  /**
   * The CSS styles that will be rendered on the content container element of the ListView.
   * Supports the type of values that are supported by [ngStyle]
   */
  @Input() containerStyle!: { [key: string]: string };

  @Input() striped = true;

  @ContentChild(ListViewHeaderDirective) listViewHeader: any;
  @ContentChild(ListViewFooterDirective) listViewFooter: any;
  @ContentChild(ListViewItemDirective) listViewItem: any;

  constructor() { }

}
