import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-series-item-tooltip',
  templateUrl: './series-item-tooltip.component.html',
  styleUrls: ['./series-item-tooltip.component.scss']
})
export class SeriesItemTooltipComponent implements OnInit {
  @Input() background: string = "blue";
  @Input() format: string ="n0";
  constructor() { }

  ngOnInit(): void {
  }

}
