import { Component, Input } from '@angular/core';
import { LoaderSize, LoaderThemeColor, LoaderType } from './model';

@Component({
  selector: 'williams-ui-platform-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent {
    
    /**
     * Specifies the theme color of the Loader.
     * The possible values are: 
     * primary, secondary, tertiary, info, 
     * success, warning, error, dark, light, inverse
     */
    @Input() themeColor : LoaderThemeColor ="primary";
    
    /**
     * Specifies the size of the Loader.
     * The possible values are: small, medium, large
     */
    @Input() size : LoaderSize  ="medium";

    /**
     * Specifies the Loader animation type.
     * The possible values are:
     * pulsing, infinite-spinner, converging-spinner
     */
    @Input() type : LoaderType ="converging-spinner";
    

    constructor() { }
}