import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SecurityUtilService } from './security-util.service';
@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    constructor(private securityUtil: SecurityUtilService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const preDefRoles = route.data['roles'];
        const url: string = state.url;
        return this.validate(preDefRoles, url);
    }
    private validate(roles: any, url: string): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
        if(Array.isArray(roles) && roles.length > 0){//validate on pre-defined roles in the route such as data: {roles: ['oper', 'ship']}
            return this.securityUtil.isInRoles(roles).then(allowed => this.checkResult(allowed));
        }
        else{//validate based on assigned roles in the menu item
            return this.securityUtil.isUserInRole(url).then(allowed => this.checkResult(allowed));
        }
    }
    private checkResult(allowed: boolean): boolean{
        if(!allowed){
            this.router.navigateByUrl("/");
        }
        return allowed;
    }
}
