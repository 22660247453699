import { AfterViewInit, Component, ContentChild, Input, OnInit, ViewChild } from '@angular/core';

import { CategoryAxisComponent } from './axis/category-axis/category-axis.component';
import { ValueAxisComponent } from './axis/value-axis/value-axis.component';
import { ChartTitleComponent } from './chart-title/chart-title.component';
import { SeriesComponent } from './series/series.component';

@Component({
  selector: 'williams-ui-platform-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {
  @Input() classList = "";

  @ViewChild('kendoChartInstance') kendoChartInstance!: ChartComponent;
  @ContentChild(CategoryAxisComponent) categoryAxisComponent!: CategoryAxisComponent;
  @ContentChild(SeriesComponent) seriesComponent!: SeriesComponent;
  @ContentChild(ChartTitleComponent) chartTitleComponent!: ChartTitleComponent;
  @ContentChild(ValueAxisComponent) valueAxisComponent!: ValueAxisComponent;
  
  constructor() { }
  
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

}
