import {
  firstDayOfMonth as kendoFirstDayOfMonth,
  lastDayOfMonth as kendoLastDayOfMonth,
} from "@progress/kendo-date-math";

export const firstDayOfMonth = (date: Date) => {
  return kendoFirstDayOfMonth(date);
};

export const lastDayOfMonth = (date: Date) => {
  return kendoLastDayOfMonth(date);
};
