import {Component, Input} from "@angular/core";
import {GridColumnDefExt} from "../models/grid-column-def-ext";

@Component({
    selector: 'williams-ui-platform-grid-view-layout',
    templateUrl: './grid-view-layout.component.html'
})
export class GridViewLayoutComponent {
    @Input() data: Array<any> = [];
    @Input() columns: Array<GridColumnDefExt> = [];
    @Input() hideHeader: boolean = true;
}