import {ElementRef, Injectable} from '@angular/core';
import { parseDate, toString, parseNumber} from '@progress/kendo-angular-intl';
import {ConfirmDialogComponent, DialogService} from "@williams-ui-platform/w-ui-angular";
import {Observable, take, tap} from "rxjs";
import {Constant, ContentSize} from "../model";

@Injectable({
    providedIn: 'root'
})
export class AppUtilService {
    constructor(private dialogService: DialogService) {
    }

    isDateString(value: any, format: any = Constant.DEFAULT_DATE_FORMATS) {
        return typeof value === "string" && parseDate(value, format) instanceof Date;
    }

    isDate(value: any): boolean {
        return value ? (value instanceof Date || this.isDateString(value)) : false;
    }
    formatPhone(value: any){
        if(value){
            value = String(value);
            return value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
        else{
            return "";
        }
    }

    parseDate(value: any, format: any = Constant.DEFAULT_DATE_FORMATS): Date {
        return parseDate(value, format);
    }

    formatDate(value: Date, withTime: boolean = false, formatStr?: any): string {
        formatStr = formatStr || (withTime? Constant.DEFAULT_DATE_TIME_FORMAT : Constant.DATE_FORMAT);
        return this.format(value, formatStr);
    }

    parseNumber(value: any): number {
        return parseNumber(value);
    }

    formatNumber(value: number, formatStr: string = "n0"): string {
        return this.format(value, formatStr);
    }

    format(value: any, format: any): string {
        return toString(value, format);
    }

    formatCurrency(value: number, formatStr: string = "a"): string {
        return this.format(value, formatStr);//a = ($1,2354.57), c = -$1,2354.57
    }

    formatPercentage(value: number, formatStr: string = "p"): string {
        return this.format(value, formatStr);
    }

    find(items: any[], fieldName: string, fieldValue: any, childrenFieldName?: string, predicate?: (item: any) => boolean): any {
        let foundItem: any;
        items.every(item => {
            if (predicate) {
                if (predicate(item)) {
                    foundItem = item;
                }
            } else if (item[fieldName] == fieldValue) {
                foundItem = item;
            }

            if (!foundItem && childrenFieldName && item[childrenFieldName]) {
                foundItem = this.find(item[childrenFieldName], fieldName, fieldValue, childrenFieldName, predicate);
            }

            return !foundItem;//break the loop if found the item
        });
        return foundItem;
    }

    findAll(items: any[], fieldName: string, fieldValue: any, childrenFieldName?: string, predicate?: (item: any) => boolean): any[] {
        let foundItems: any[] = [];
        items.every(item => {
            if (predicate) {
                if (predicate(item)) {
                    foundItems.push(item)
                }
            }
            else if (item[fieldName] == fieldValue) {
                foundItems.push(item);
            }

            if (childrenFieldName && item[childrenFieldName] && item[childrenFieldName].length > 0) {
                const subFoundItems = this.findAll(item[childrenFieldName], fieldName, fieldValue, childrenFieldName, predicate);
                if (subFoundItems && subFoundItems.length > 0) {
                    foundItems = foundItems.concat(subFoundItems);
                }
            }

            return true;//keep looping through all items
        });
        return foundItems;
    }
    enableMenuItem(menuItems: any[], menuText: string, enabled: boolean, menuTextFieldName: string = "text"
                   , childrenFieldName: string = "items") {//find all items including sub items
        const item = this.find(menuItems, menuTextFieldName, menuText, childrenFieldName);
        if (item) {
            item.disabled = !enabled;
        }
    }
    showMenuItem(menuItems: any[], menuText: string, shown: boolean, menuTextFieldName: string = "text"
        , childrenFieldName: string = "items") {//find all items including sub items
        const item = this.find(menuItems, menuTextFieldName, menuText, childrenFieldName);
        if (item) {
            item.hidden = !shown;
        }
    }
    mapFormMsgs(msgs: any[], msgMap: Map<string, any>){
        return msgs.reduce((result, item) => {
            for (let key in item) {
                const errors = this.mapMsgs(key, item[key], msgMap);
                errors.forEach(err => {
                    if (!result.includes(err)) {
                        result.push(err);
                    }
                })
            }
            return result;
        }, []);
    }
    mapMsgs(key: string, item: { [key: string]: any }, msgMap: Map<string, any>){
        const result = [];
        const errors = msgMap.get(key) as { [key: string]: any };
        for (let key in item) {
            result.push(errors[key]);
        }
        return result;
    }
    confirm(title: string, content: string, confirmAction = { text: "Cancel changes", value: "yes" }
            , cancelAction={ text: "Keep working", value: 'no' }): Promise<boolean> {
        return new Promise(resolve => {
            this.dialogService.openDialog({
                title: title,
                content: content,
                cancelAction: cancelAction,
                confirmAction: confirmAction
            }, ConfirmDialogComponent).result
                .pipe(
                    take(1),
                    tap((result: any) => {
                        resolve(result.value === confirmAction.value);
                    }))
                .subscribe()
        });
    }
    simpleConfirm(content: string, cancelText = "No", confirmText = "Yes"
        , title = "Please Confirm"): Observable<any>{
        return this.dialogService.openDialog({
            title: title,
            content: content,
            cancelAction : { text: cancelText, value: false },
            confirmAction: { text: confirmText, value: true }
        }, ConfirmDialogComponent).result;
    }

    confirmUnsavedChanges(content: string, cancelText = "Keep working", confirmText = "Close and discard changes", title = "Unsaved changes"): Observable<any>{
        return this.simpleConfirm(content, cancelText, confirmText, title)
    }
    calcHeight(eleRef: ElementRef | ContentSize, offsetEle?: ElementRef | NodeList, extraOffset: number = 0): number {
        const contentSize = eleRef instanceof ContentSize ? eleRef : this.getContentSize(eleRef);
        let calcHeight: number | undefined;
        if (offsetEle) {
            if (offsetEle instanceof NodeList) {
                let totalOffsetHeight = 0;
                offsetEle.forEach((node: any) => totalOffsetHeight = totalOffsetHeight + node.offsetHeight);
                calcHeight = contentSize.height - totalOffsetHeight;
            }
            else {
                calcHeight = contentSize.height - offsetEle.nativeElement.offsetHeight;
            }
        }
        else {
            calcHeight = contentSize.height;
        }

        return calcHeight + extraOffset;
    };

    getContentSize(contentEle: ElementRef | undefined): ContentSize {
        const nativeEle = contentEle?.nativeElement;
        return new ContentSize(
            nativeEle?.clientHeight,
            nativeEle?.clientWidth,
            nativeEle?.offsetTop,
            nativeEle?.offsetLeft,
            nativeEle
        );
    }
}
