<kendo-splitbutton
  [data]="data"
  buttonClass="main-btn"
  [arrowButtonIcon]="arrowButtonIcon"
  [icon]="icon"
  [svgIcon]="svgIcon"
  [imageUrl]="imageUrl"
  [text]="text"
  [type]="type"
  [size]="size"
  [fillMode]="fillMode"
  [rounded]="rounded"
  [themeColor]="themeColor"
  [disabled]="disabled"
  (buttonClick)="btnClick.emit()"
  (close)="close($event)"
  (itemClick)="itemClicked($event)"
  (blur)="blurEventCalled($event)"
  #kendoSplitButtonInstance="kendoSplitButton"
>
<mat-icon  *ngIf="matIcon" [fontIcon]="matIcon" [class]="mantIconClassName"></mat-icon>
<ng-content></ng-content>
<ng-template kendoSplitButtonItemTemplate let-dataItem *ngIf="splitButtonTemplateDirective?.iconTemplate">
  <ng-container   *ngTemplateOutlet="splitButtonTemplateDirective.iconTemplate;context: { dataItem }">
  </ng-container>
</ng-template>
</kendo-splitbutton>
