<kendo-progressbar
  [value]="value"
  [min]="min"
  [max]="max"
  [indeterminate]="indeterminate"
  [orientation]="orientation"
  [reverse]="reverse"
  [label]="label"
  [animation]="animation"
  [emptyCssStyle]="emptyStyles"
  [progressCssStyle]="progressStyles"
  [disabled]="disabled"
>
</kendo-progressbar>
