import { Pipe, PipeTransform } from "@angular/core";
import { formatNumber } from "@progress/kendo-angular-intl";

@Pipe({
  name: "wNumberFormatter",
})
export class WNumberFormatterPipe implements PipeTransform {
  transform(
    value: any,
    options?: { format?: string; addBracketsForNegativeValue?: boolean }
  ): string {
    if (value == null) {
      return "";
    }
    let formattedNumber: string = formatNumber(value, options?.format ?? "n");

    if (options?.addBracketsForNegativeValue && value < 0) {
      formattedNumber = `(${formattedNumber.replace("-", "")})`;
    }

    return formattedNumber;
  }
}
