import { Component, Input } from '@angular/core';
import { LabelFn, LabelPosition, LabelSettings, LabelType, ProgressBarOrientation } from '@progress/kendo-angular-progressbar';
// import { LoaderSize, LoaderThemeColor, LoaderType } from './model';

@Component({
  selector: 'williams-ui-platform-progress-bar',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent {
    /**
     * Specifies the value of the progress bar.
     * The possible values are: 0 to 100
     */
    @Input() value=0;

    @Input() min=0;

    @Input() max=100;

    @Input() indeterminate=false;

    @Input() disabled = false;

    @Input() orientation: ProgressBarOrientation = 'horizontal';

    @Input() reverse = false;

    @Input() labelText:LabelType | LabelFn | any = 'percent'; // possible values are : Empty, percent

    @Input() position:LabelPosition = "start"; /// possible values : start, center, end

    @Input() visible = true;

    @Input() label: LabelSettings = {
        visible: this.visible,
        format: this.labelText,
        position: this.position 
      };

    @Input() animation = false;
    
    @Input() progressStyles: { [key: string]: string } = {
      background:'#8EBC00'
    };
    
    @Input() emptyStyles: { [key: string]: string } = {};

    /**
     * Specifies the theme color of the Loader.
     * The possible values are: 
     * primary, secondary, tertiary, info, 
     * success, warning, error, dark, light, inverse
     */
    // @Input() themeColor : LoaderThemeColor ="primary";
    
    /**
     * Specifies the size of the Loader.
     * The possible values are: small, medium, large
     */
    // @Input() size : LoaderSize  ="medium";

    /**
     * Specifies the Loader animation type.
     * The possible values are:
     * pulsing, infinite-spinner, converging-spinner
     */
    // @Input() type : LoaderType ="converging-spinner";
    

    constructor() { }
}