<kendo-chart #kendoChartInstance="kendoChart" [class]="classList">
  <kendo-chart-title [text]="chartTitleComponent.text"></kendo-chart-title>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item
      *ngFor="let axisItem of categoryAxisComponent?.axisItems; let i = index"
      [categories]="axisItem.categories"
      [title]="axisItem.title"
      [labels]="axisItem.labels"
    >
    </kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
  <kendo-chart-value-axis>
    <ng-container *ngFor="let valueAxisItem of valueAxisComponent?.axisItems; let i = index">
          <kendo-chart-value-axis-item *ngIf="valueAxisItem?.min"
          [title]="valueAxisItem.title"
          [labels]="valueAxisItem?.labels"
          [min]="valueAxisItem.min"
          
        >
        </kendo-chart-value-axis-item>
        <kendo-chart-value-axis-item *ngIf="!valueAxisItem?.min"
        [title]="valueAxisItem.title"
        [labels]="valueAxisItem?.labels"
      >
      </kendo-chart-value-axis-item>
    </ng-container>
  </kendo-chart-value-axis>
  <kendo-chart-series>
    <kendo-chart-series-item-tooltip
      *ngIf="seriesComponent?.seriesItemTooltipComponent"
      [background]="seriesComponent.seriesItemTooltipComponent.background"
      [format]="seriesComponent.seriesItemTooltipComponent.format"
    >
    </kendo-chart-series-item-tooltip>
    <kendo-chart-series-item
      *ngFor="let seriesItem of seriesComponent?.seriesItems; let i = index"
      [type]="seriesItem.type"
      [color]="seriesItem.color"
      [data]="seriesItem.data"
      [field]="seriesItem.field"
      [categoryField]="seriesItem.categoryField"
    >
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
